<template>
  <b-col
    class="p-0 right-section"
    cols="8"
  >
    <div v-if="isDefault">
      <img
        alt="login-AC ISO"
        class="img-login-right-bg"
        :src="`${publicPath}static/img/login_screen_tenacy.png`"
      />
      <div class="box-login-right">
        <img
          alt="login-ACISO"
          :src="`${publicPath}static/img/Tenacy_empowering.png`"
          style="width: 70%"
        />
      </div>
    </div>
    <div
      v-else
      class="w-100 h-100 p-0 right-section py-4"
      :style="{ 'background-color': background_color }"
    >
      <b-container class="w-70">
        <b-row
          class="mb-4"
          style="margin-left: 0px"
        >
          <h2
            class="title"
            :class="isDarkBackground ? 'title-dark' : 'title-light'"
          >
            {{ title }}
          </h2>
        </b-row>
        <b-row
          class="mb-4 content"
          style="margin-left: 0px"
        >
          <viewer
            :class="isDarkBackground ? 'viewer-markdown-login-dark' : 'viewer-markdown-login-light'"
            linkify
            :value="content"
          />
        </b-row>
        <b-row
          v-if="image_url"
          class="justify-content-center pl-1"
        >
          <img
            alt="login_image"
            class="mt-2 login-img"
            :src="image_url"
          />
        </b-row>
        <b-button
          v-if="hasButton"
          class="w-40 text-bold login-btn"
          :class="isDarkBackground ? 'login-btn-dark' : 'login-btn-light'"
          :style="{ 'background-color': background_color_button, color: font_color_button }"
          @click="redirect"
          @mouseleave="resetBackgroundColor"
          @mouseover="changeBackgroundColor"
        >
          {{ button_label }}
        </b-button>
      </b-container>
    </div>
  </b-col>
</template>

<script>
import axios from 'axios'
import { config } from '@/config.settings'
import { Viewer } from '@toast-ui/vue-editor'
import { isLanguageFrench } from '@/helpers/Language.helpers'

export default {
  name: 'auth-col-right',
  components: {
    Viewer,
  },
  data: function () {
    return {
      title: '',
      content: '',
      button_label: '',
      button_url: '',
      image_url: '',
      isDefault: false,
      background_color: '#0A4650',
      background_color_button: '#0A4650',
      font_color_button: '#ffffff',
      hasButton: false,
      publicPath: import.meta.env.BASE_URL,
    }
  },
  computed: {
    isDarkBackground() {
      return this.background_color === '#0A4650' || this.background_color === '#267C6E'
    },
  },
  mounted() {
    void axios({
      url: config.base_url + '/login_message/1',
      headers: { 'x-page': window.location.pathname },
      method: 'GET',
    }).then((resp) => {
      if (resp.data.ok === true) {
        if (
          resp.data.object.title_fr.length > 0 ||
          resp.data.object.content_fr.length > 0 ||
          resp.data.object.content_fr.length > 0 ||
          resp.data.object.content_en.length > 0 ||
          resp.data.object.label_button_fr.length > 0 ||
          resp.data.object.label_button_en.length > 0
        ) {
          this.isDefault = false
          this.$log.debug('LABEL OK')
          if (isLanguageFrench()) {
            this.title = resp.data.object.title_fr
            this.content = resp.data.object.content_fr
            this.button_label = resp.data.object.label_button_fr
          } else {
            this.title = resp.data.object.title_en
            this.content = resp.data.object.content_en
            this.button_label = resp.data.object.label_button_en
          }
          if (resp.data.object.label_button_en.length > 0 || resp.data.object.label_button_fr.length > 0) {
            this.hasButton = true
            this.button_url = resp.data.object.link_id
          }
          this.background_color = this.getColor(resp.data.object.background_color)
          this.background_color_button = this.getColor(resp.data.object.background_color)
        } else {
          this.isDefault = true
        }
      } else {
        this.isDefault = true
        throw new Error(resp.data.message)
      }
    })

    //get th login message image
    axios({
      url: config.base_url + '/login_message/logo/1',
      headers: { 'x-page': window.location.pathname },
      method: 'GET',
    })
      .then((response) => {
        if (response.data.ok) {
          if (response.data.url) {
            this.isDefault = false
            this.image_url = response.data.url
          } else {
            this.isDefault = true
          }
        }
      })
      .catch(() => {
        this.isDefault = true
      })
  },
  methods: {
    resetBackgroundColor() {
      this.background_color_button = this.background_color
      this.font_color_button = '#ffffff'
    },
    changeBackgroundColor() {
      this.background_color_button = '#ffffff'
      this.font_color_button = this.background_color
    },
    getColor(value) {
      if (value === 'PRIMARY') {
        return '#0A4650'
      }
      if (value === 'TERTIARY') {
        return '#267C6E'
      }
      if (value === 'BLUE') {
        return '#61A0FF'
      }
      if (value === 'CYAN') {
        return '#50EBD2'
      }
    },
    redirect() {
      window.open(this.button_url, '_blank')
    },
  },
}
</script>

<style scoped>
.img-login-right-bg {
  height: 100vh;
  width: 100%;
}
.right-section {
  color: #ffffff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0;
  overflow: hidden;
}
.title {
  font-weight: 700;
  font-size: 50px;
}
.title-dark {
  color: #ffffff;
}
.title-light {
  color: rgb(10, 70, 80);
}
.content {
  color: #ffffff !important;
}

.bottom-corner-img {
  width: 125px;
  height: 125px;
  position: absolute;
  bottom: 4px;
  right: 11px;
  transform: rotate(4deg);
}
.top-corner-img {
  width: 125px;
  height: 125px;
  position: absolute;
  left: 0;
  top: 0;
  transform: rotate(185deg);
}
.login-btn {
  height: 40px;
  font-size: 18px;
  font-weight: normal;
  margin-top: 3rem;
  object-fit: cover;
}
.login-btn-dark {
  border: 1px solid #ffffff;
  color: #ffffff;
}
.login-btn-light {
  border: 1px solid rgb(10, 70, 80);
  color: rgb(10, 70, 80) !important;
}
</style>
