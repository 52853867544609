<template>
  <div>
    <keyboard-events @keyup="keyboardEvent" />
    <loading
      :active.sync="$store.getters.isLoading"
      :can-cancel="false"
      :height="32"
      :is-full-page="true"
      :width="32"
    />

    <!-- /.login-logo -->
    <b-row
      class="p-0 m-0"
      style="background: #f4f6f9"
    >
      <b-col
        align-self="center"
        class="left-section p-0 m-0"
        lg="4"
        md="12"
      >
        <div class="login-div p-0">
          <div class="login-logo mb-3">
            <img
              alt="TENACY"
              class="logo-login-img"
              :src="`${publicPath}static/img/Tenacy_couleur_borderless.svg`"
            />
          </div>

          <div class="mb-3 d-inline-flex">
            <b-button
              v-if="step > 1"
              class="go-back mr-2"
              variant="outline-primary"
              @click="step = 1"
            >
              <i
                v-if="step > 1"
                class="far fa-arrow-left"
                @click="step = 1"
              />
            </b-button>
            <span
              v-if="step > 1"
              style="overflow-wrap: anywhere"
              >{{ email }}</span
            >
            <br v-if="step > 1" />
          </div>

          <form
            :data-vv-scope="scope"
            @submit.prevent.stop="$emit('dummy')"
          >
            <form-field
              v-show="step === 1"
              ref="email"
              v-model="email"
              v-validate="'required'"
              autocomplete="username"
              data-vv-validate-on="input"
              :disabled="step != 1"
              :error="errors.first('form_login.email')"
              :label="_('Email')"
              name="email"
              no-check
              placeholder="contact@example.com"
              type="text"
              @blur="handleBlur('email')"
              @check-value="autofillCheck($event, 'email')"
              @input="handleInput('email')"
            />

            <form-field
              ref="password"
              v-model="password"
              autocomplete="current-password"
              :class="{ 'd-none': step === 1 }"
              :datahidden="step === 1"
              :error="errors.first('form_login.password')"
              :label="_('Password')"
              name="password"
              no-check
              :placeholder="_('Password')"
              :style="password_style"
              type="password"
              @check-value="autofillCheck($event, 'password')"
            />

            <form-field
              v-if="step === 3"
              ref="authentication_code"
              v-model="authentication_code"
              v-validate="'length:6|numeric'"
              :datahidden="step === 1"
              :error="errors.first('form_login.authentication_code')"
              :label="_('Authentication code')"
              name="authentication_code"
              no-check
              :placeholder="_('Authentication code')"
              :style="authentication_code_style"
            />

            <div class="row">
              <div class="col-12">
                <b-button
                  v-if="step === 1"
                  id="sign_in1"
                  ref="sign_in1"
                  class="w-100 login-button tenacy-btn ripple"
                  :disabled="loading"
                  href="#"
                  variant="primary"
                  @click.stop="signIn"
                >
                  <translate key="next">Next</translate>
                </b-button>
                <b-button
                  v-else
                  ref="sign_in2"
                  class="w-100 login-button login-button tenacy-btn ripple"
                  href="#"
                  variant="primary"
                  @click.stop="signIn"
                >
                  <translate key="si">Sign in</translate>
                </b-button>
              </div>
            </div>
            <div
              v-if="loading"
              class="text-center mt-2"
            >
              <i class="fa fa-2x text-primary fa-circle-notch fa-spin" />
            </div>
          </form>
          <b-row class="mt-3">
            <b-col class="text-right"
              ><a
                href="#"
                @click="forgotPassword"
              >
                <translate>I forgot my password</translate>
              </a></b-col
            >
          </b-row>
        </div>
      </b-col>
      <auth-col-right class="hide-col" />
    </b-row>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import FormField from '../ui/form-field.vue'
import vv_fr from 'vee-validate/dist/locale/fr'
import UnauthPageMixin from '../mixins/unauth-page-mixin'
import KeyboardEvents from '../ui/keyboard-events.vue'
import AuthColRight from '../ui/auth-col-right.vue'
import throttle from 'lodash/throttle'
import { isLanguageFrench } from '@/helpers/Language.helpers'

export default {
  name: 'sign-in',
  components: {
    FormField,
    Loading,
    KeyboardEvents,
    AuthColRight,
  },
  mixins: [UnauthPageMixin],
  data: function () {
    return {
      email: '',
      step: 1,
      password: '',
      recaptca_enabled: false,
      authentication_code: '',
      validate_blured: {},
      validate_touched: {},
      scope: 'form_login',
      publicPath: import.meta.env.BASE_URL,
    }
  },
  computed: {
    password_style() {
      if (this.step === 2 || this.step === 3) {
        return {}
      } else {
        return {
          opacity: 0,
          height: '0px !important',
          'margin-bottom': '0',
        }
      }
    },
    authentication_code_style() {
      if (this.step === 3) {
        return {}
      } else {
        return {
          opacity: 0,
          height: '0px !important',
          'margin-bottom': '0',
        }
      }
    },
  },
  mounted() {
    if (isLanguageFrench()) {
      this.$language.current = 'fr_FR'
    } else {
      this.$language.current = 'en_US'
    }
    this.focusInput()
  },
  methods: {
    autofillCheck(event, field) {
      if (this[field] !== event) {
        this[field] = event
      }
    },
    signIn: function () {
      if (this.step === 1) {
        this.$refs.email.triggerCheckValue()
        this.signIn1()
      } else {
        if (this.$refs.password) {
          this.$refs.password.triggerCheckValue()
          this.signIn2()
        }
      }
    },
    focusInput() {
      let found = false
      if (this.step === 1) {
        if (this.$refs.email) {
          this.$refs.email.focus()
          found = true
        }
      } else if (this.step === 2 || this.step === 3) {
        if (this.$refs.password) {
          this.$refs.password.focus()
          found = true
        }
      }

      if (!found) {
        setTimeout(() => {
          this.focusInput()
        }, 200)
      }
    },
    processError(err, unloading = true) {
      if (unloading) {
        this.$store.commit('unloading')
      }
      this.$log.debug(err)
      this.$toast.alert(err)
      this.email = ''
      this.password = ''
      this.authentication_code = ''
      if (this.$refs.email) {
        this.$refs.email.touched = false
      }
      if (this.$refs.password) {
        this.$refs.password.touched = false
      }
      if (this.$refs.authentication_code) {
        this.$refs.authentication_code.touched = false
      }
    },
    signIn1: function () {
      const _this = this
      this.$validator
        .validateAll('form_login')
        .then((res) => {
          if (res) {
            this.loading = true
            const email = this.email
            this.$store
              .dispatch('login1', { email })
              .then((user) => {
                this.loading = false
                if (user && user.saml) {
                  this.$toast.info(user.message)
                  window.location.href = user.redirect
                } else if (user && user.mfa) {
                  this.step = 3
                  this.$nextTick(() => this.focusInput())
                } else if (user) {
                  this.step = 2
                  this.$nextTick(() => this.focusInput())
                } else {
                  throw new Error()
                }
              })
              .catch((err) => {
                this.$log.error(err)
                this.loading = false
                this.processError(this._('Network error'), false)
              })
          } else {
            this.$log.debug('VALIDATION FAILED')
          }
        })
        .catch((err) => {
          _this.$log.debug(err)
        })
        .catch((err) => {
          _this.$log.debug(err)
        })
    },
    signIn2: function () {
      const _this = this
      this.$validator
        .validateAll('form_login')
        .then((res) => {
          if (res) {
            this.$store.commit('loading')
            const email = this.email
            const password = this.password
            const authentication_code = this.authentication_code
            this.$store
              .dispatch('login', { email, password, authentication_code })
              .then((user) => {
                this.$store.commit('unloading')
                if (user.saml) {
                  this.$log.error('Internal error, SAML redirect failed')
                } else {
                  if (user.lang === 'fr') {
                    this.$language.current = 'fr_FR'
                    this.$validator.localize('fr', vv_fr)
                  } else {
                    this.$language.current = 'en_US'
                  }
                  if (this.$route.query.redirect) {
                    this.$router.push(this.$route.query.redirect).catch(() => {})
                  } else {
                    this.$router.push(this.$store.getters.isPublic ? '/admin/customer' : '/home').catch(() => {})
                  }
                }
              })
              .catch((err) => {
                this.processError(err)
                this.step = 1
              })
          }
        })
        .catch((err) => {
          _this.$log.debug(err)
        })
    },
    forgotPassword: function () {
      this.$router.push({ name: 'forgot_password' }).catch(() => {})
    },
    handleBlur: function (name) {
      this.validate_blured[name] = true
    },
    handleInput: function (name) {
      this.validate_touched[name] = true
      if (this.validate_blured[name] === true) {
        this.$validator.validate(`${this.scope}.${name}`)
      }
    },
    keyboardEvent: throttle(
      function (e) {
        if (e.which === 13) {
          this.$log.debug('SIGN IN !!')
          this.signIn()
        }
      },
      200,
      { leading: false, trailing: true }
    ),
  },
}
</script>

<style scoped>
.login-div {
  margin: 22% !important;
}
.left-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding-left: 4px;
}
</style>
