<template>
  <b-modal
    id="congratulations-modal"
    content-class="congratulations"
    hide-footer
    hide-header
    name="congratulations-modal"
    size="lg"
  >
    <img
      alt="congratulations"
      :src="`${publicPath}static/img/congratulations.svg`"
    />
    <div class="bloc-card mt-4 mb-5 p-1">
      <h2 class="text-center"><translate key="congratulations">Congratulations!</translate></h2>
      <div class="ml-4 mr-4">
        <div class="mt-4">
          {{ completed_startup_description }}
        </div>
        <div>
          <translate key="completed_startup2"
            >But this is just the beginning, watch for things to be completed by clicking on the</translate
          >
          <i
            class="far fa-bell-on ml-1 mr-1"
            style="color: gold"
          />
          <translate key="completed_startup_notif">notification in the top bar.</translate>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'congratulations-modal',

  data() {
    return {
      message: '',
      publicPath: import.meta.env.BASE_URL,
    }
  },
  computed: {
    completed_startup_description() {
      return this._(
        "You have completed Tenacy' start-up wizard and are on the right track to reach your security objectives."
      )
    },
  },
}
</script>

<style scoped>
::v-deep .congratulations {
  padding: 0rem !important;
  border-radius: 22px;
}

::v-deep .modal-body {
  padding: 0rem !important;
}
</style>
