<template>
  <NdsPanel
    class="newsPanel"
    :show="showNewsPanel"
    @close="emit('close')"
  >
    <template #header>
      <h6>{{ `${$t('shared.hello')}, ${$store.getters.name}` }}</h6>
    </template>
    <template #body>
      <div class="newsPanel__body">
        <section class="newsPanel__textSection">
          <h6>{{ $t('news-panel.title') }}</h6>

          <p>
            {{ $t('news-panel.description') }}
          </p>
        </section>
        <div class="newsPanel__imgSection">
          <NewsImg
            data-testid="news-panel-img"
            :image-url="imageUrl"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <NdsButton
        :color="NdsButtonColor.PRIMARY"
        data-testid="news-panel-close-btn"
        @click="emit('close')"
      >
        {{ $t('shared.close') }}
      </NdsButton>
    </template>
  </NdsPanel>
</template>

<script setup lang="ts">
import NdsPanel from '@/components/NewDesignSystem/NdsPanel/NdsPanel.vue'
import NewsImg from '@/contexts/shared/news/NewsImg.vue'
import { NdsButtonColor } from '@/components/NewDesignSystem/NdsButton/NdsButton.interfaces'
import NdsButton from '@/components/NewDesignSystem/NdsButton/NdsButton.vue'
import Vue, { computed } from 'vue'

defineProps({
  showNewsPanel: {
    type: Boolean,
    required: true,
  },
})
const emit = defineEmits<(e: 'close') => void>()

const lang = Vue.prototype.getCurrentLang()

const imageUrl = computed(() => {
  return `static/NewsPanel_${lang}.png`
})
</script>

<style scoped lang="postcss">
.newsPanel {
  &__body {
    @apply tw-flex;
  }
  &__textSection,
  &__imgSection {
    flex: 1;
  }
}
</style>
