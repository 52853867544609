import { _, allRoles, isAuthorized, lazyLoadView, piloteAndAuditor, requiresInternal, setNavL2 } from '@/router/routing'
import type { Route, RouteConfig } from 'vue-router'

export const incidentsRoutes: RouteConfig = {
  path: '/incidents',
  name: 'incidents',
  component: () => lazyLoadView(import('@/contexts/incidents/IncidentsView.vue')),
  meta: {
    title: _('Incidents'),
    icon: 'fire-alt',
    is_authorized: (route: Route) => isAuthorized(route),
    requires_auth: true,
    requires_tenant: true,
    requires_role: piloteAndAuditor,
    hidden_for_fp_assessment: true,
    doc_id: 'incident',
    feature_id: 'INCIDENTS',
  },
}

export const incidentsContributionRoutes: RouteConfig = {
  path: 'incidents',
  name: 'myincidents',
  component: () => lazyLoadView(import('@/contexts/incidents/IncidentsContributionView.vue')),
  beforeEnter: setNavL2,
  meta: {
    title: _('Incidents'),
    icon: 'fire-alt',
    is_authorized: (route: Route) => isAuthorized(route),
    requires_auth: true,
    requires_tenant: true,
    hidden_for_fp_assessment: true,
    requires_role: allRoles,
    requires_internal: () => requiresInternal(),
    doc_id: 'my_incidents',
    nb_contribution: 'nb_incidents',
    feature_id: 'INCIDENTS',
  },
}
