import MainApp from '@/components/main-app.vue'
import { allRoles, lazyLoadView, piloteAndAuditor, redirectHome, _, isAuthorized } from '@/router/routing'
import type { Route, RouteConfig } from 'vue-router'

// /app/... are routes that are neither in the menu nor in the wheel menu

export const appRoutes: RouteConfig[] = [
  {
    path: '/app',
    name: 'common',
    component: MainApp,
    redirect: () => redirectHome(),
    meta: {
      dummy: true,
    },
    children: [
      {
        path: '/reviews',
        name: 'review',
        component: () => lazyLoadView(import('@/components/pages/reviews.vue')),
        meta: {
          title: _('Reviews'),
          is_authorized: (route: Route) => isAuthorized(route),
          requires_auth: true,
          requires_tenant: true,
          requires_role: piloteAndAuditor,
          doc_id: 'review',
        },
      },
      {
        path: '/my_objects',
        name: 'my_objects',
        component: () => lazyLoadView(import('@/components/pages/user-objects.vue')),
        meta: {
          title: null,
          icon: 'boxes',
          is_authorized: (route: Route) => isAuthorized(route),
          requires_auth: true,
          requires_tenant: true,
          requires_role: allRoles,
          doc_id: 'my_objects',
        },
      },
      {
        path: '/doc',
        name: 'documentation',
        component: () => lazyLoadView(import('@/components/pages/documentation.vue')),
        meta: {
          title: _('Documentation'),
          is_authorized: (route: Route) => isAuthorized(route),
          requires_auth: true,
          requires_tenant: false,
          requires_role: allRoles,
          doc_id: 'documentation',
        },
      },
      {
        path: '/doc/:id',
        name: 'doc_link',
        component: () => lazyLoadView(import('@/components/pages/documentation.vue')),
        meta: {
          title: _('Documentation'),
          is_authorized: (route: Route) => isAuthorized(route),
          requires_auth: true,
          requires_tenant: false,
          requires_role: allRoles,
          doc_id: 'documentation',
        },
      },
      {
        path: '/cgu',
        name: 'cgu',
        component: () => lazyLoadView(import('@/components/pages/cgu.vue')),
        meta: {
          title: _('Terms of use'),
          is_authorized: (route: Route) => isAuthorized(route),
          requires_auth: true,
          requires_tenant: false,
          requires_role: allRoles,
        },
      },
    ],
  },
]
