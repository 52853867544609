export enum NdsColor {
  NEUTRAL_50 = '#f7f8f7',
  NEUTRAL_100 = '#f1f2f1',
  NEUTRAL_200 = '#e4e6e5',
  NEUTRAL_300 = '#cfd2d1',
  NEUTRAL_400 = '#b1b5b4',
  NEUTRAL_500 = '#9ca09f',
  NEUTRAL_600 = '#7f8382',
  NEUTRAL_700 = '#696d6c',
  NEUTRAL_800 = '#585c5b',
  NEUTRAL_900 = '#4c4f4f',
  NEUTRAL_950 = '#232424',

  PRIMARY_50 = '#f0fdfa',
  PRIMARY_100 = '#cbfcf1',
  PRIMARY_200 = '#97f8e5',
  PRIMARY_400 = '#2ad7c0',
  PRIMARY_500 = '#11bba7',
  PRIMARY_600 = '#0b9689',
  PRIMARY_700 = '#0d786f',
  PRIMARY_950 = '#03302f',

  SECONDARY_50 = '#eff6ff',
  SECONDARY_100 = '#dcebfd',
  SECONDARY_200 = '#c1dcfc',
  SECONDARY_400 = '#64a8f6',
  SECONDARY_500 = '#5292f2',
  SECONDARY_600 = '#2b69e5',
  SECONDARY_700 = '#2255d3',
  SECONDARY_950 = '#192752',

  INFO_50 = '#fdfee8',
  INFO_200 = '#ffff89',
  INFO_400 = '#fdeb12',
  INFO_500 = '#ecd006',
  INFO_700 = '#a37505',
  INFO_950 = '#432805',

  WARNING_50 = '#fff8ed',
  WARNING_100 = '#fde9cf',
  WARNING_200 = '#fdd9ab',
  WARNING_400 = '#fa9f4b',
  WARNING_500 = '#f77a18',
  WARNING_700 = '#c1470d',
  WARNING_950 = '#431607',

  DANGER_50 = '#fdf2f6',
  DANGER_100 = '#fbe8ef',
  DANGER_200 = '#f9d1e0',
  DANGER_400 = '#ee789f',
  DANGER_500 = '#e34474',
  DANGER_600 = '#d32f59',
  DANGER_700 = '#b71f41',
  DANGER_950 = '#4d0a18',

  SUCCESS_50 = '#f0fdfa',
  SUCCESS_100 = '#cbfcf1',
  SUCCESS_200 = '#97f8e5',
  SUCCESS_400 = '#2ad7c0',
  SUCCESS_500 = '#11bba7',
  SUCCESS_600 = '#0b9689',
  SUCCESS_700 = '#0d786f',
  SUCCESS_950 = '#03302f',

  WHITE = '#ffffff',
  BLACK = '#000000',
  TRANSPARENT = 'transparent',
}
