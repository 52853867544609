import Vue from 'vue'
import { capitalize, removeMarkdownFromText, truncate, upper } from '@/helpers/Text.helpers'
import {
  date,
  dateLarge,
  datetime,
  datetimeSmall,
  localDatetime,
  localDatetimeDate,
  localDatetimeLarge,
} from '@/helpers/Date.helpers'
import { currency, largeAmount } from '@/helpers/Numbers.helpers'

Vue.filter('upper', upper)
Vue.filter('capitalize', capitalize)
Vue.filter('truncate', truncate)
Vue.filter('removeMarkdown', removeMarkdownFromText)

Vue.filter('datetime', datetime)
Vue.filter('date', date)
Vue.filter('date_large', dateLarge)
Vue.filter('datetime_small', datetimeSmall)
Vue.filter('local_datetime', localDatetime)
Vue.filter('local_datetime_date', localDatetimeDate)
Vue.filter('local_datetime_large', localDatetimeLarge)

Vue.filter('currency', currency)
Vue.filter('large_amount', largeAmount)
