<template>
  <div />
</template>

<script>
export default {
  name: 'keyboard-events',
  created() {
    const _this = this
    this.handler = function (e) {
      _this.$emit('keyup', e)
    }
    window.addEventListener('keyup', this.handler)
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.handler)
  },
}
</script>

<style scoped>
div {
  display: none;
}
</style>
