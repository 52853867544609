export enum FeatureFlag {
  SE_CAMPAIGNS = 'se_campaigns',
  SECURITY_BASE_V2 = 'security_base_v2',
  SECURITY_BASE_FUNNEL = 'security_base_funnel',
  NEW_POLICY_COVERAGE = 'new_policy_coverage',
  DASHBOARD_SECTION_EDIT = 'dashboard_section_edit',
}

export interface CustomerFeatureFlag {
  enable: boolean
  feature_flag: {
    id: number
    name: string
  }
  feature_flag_id: number
  id: number
}
