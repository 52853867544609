import MainContent from '@/components/layout/main-content.vue'
import { _, isAuthorized, isGlobalPiloteOrAuditor, lazyLoadView, piloteAndAuditor } from '@/router/routing'
import type { Route, RouteConfig } from 'vue-router'

import { dashboardsRoutes } from '@/contexts/dashboards/Dashboards.routes'

export const performanceRoutes: RouteConfig = {
  path: '/org2',
  name: 'organization2',
  component: MainContent,
  meta: {
    title: _('Performance'),
    dummy: true,
    requires_auth: true,
    requires_tenant: true,
    requires_role: piloteAndAuditor,
    is_authorized: (route: Route) => isAuthorized(route),
  },
  children: [
    {
      path: '/perimeters',
      name: 'perimeters_scores',
      component: () => lazyLoadView(import('@/components/pages/perimeters.vue')),
      meta: {
        title: _('Perimeters'),
        icon: 'layer-group',
        is_authorized: (route: Route) => isAuthorized(route),
        requires_auth: true,
        requires_tenant: true,
        requires_role: piloteAndAuditor,
        doc_id: 'perimeters',
      },
      children: [],
    },
    {
      path: '/benchmark',
      name: 'dashboard_scores',
      component: () => lazyLoadView(import('@/components/pages/scores.vue')),
      meta: {
        title: _('Benchmark'),
        icon: 'chart-line',
        is_authorized: (route: Route) => isAuthorized(route),
        requires_auth: true,
        requires_tenant: true,
        requires_role: isGlobalPiloteOrAuditor,
        doc_id: 'benchmark',
        feature_id: 'POLICIES',
      },
      children: [],
    },
    dashboardsRoutes,
  ],
}
