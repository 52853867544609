import MainApp from '@/components/main-app.vue'
import { redirectHome } from '@/router/routing'
import { myRoutes } from '@/router/routes/nav-routes/My.routes'
import { operationRoutes } from '@/router/routes/nav-routes/Operation.routes'
import { performanceRoutes } from '@/router/routes/nav-routes/Performance.routes'
import { strategyRoutes } from '@/router/routes/nav-routes/Strategy.routes'
import type { RouteConfig } from 'vue-router'

// Build mother route for main navigation (use to create main menu => main-sidebar.vue)

export const navRoutes: RouteConfig[] = [
  {
    path: '/nav',
    name: 'nav',
    redirect: () => redirectHome(),
    component: MainApp,
    meta: {
      dummy: true,
    },
    // each children represent a category in the main menu.
    children: [myRoutes, operationRoutes, performanceRoutes, strategyRoutes],
  },
]
