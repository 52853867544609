<template>
  <div>
    <toggle-button
      v-model="value_"
      :color="{ checked: color_true, unchecked: color_false }"
      :disabled="disabled"
      :font-size="font_size"
      :height="height"
      :labels="{ checked: label_true, unchecked: label_false }"
      sync
      :value="value_"
      :width="width"
      @change="changed"
    />
    <b-badge
      v-if="help && help.length > 0"
      v-b-tooltip
      class="mt-1"
      :title="help"
      variant="default"
    >
      <i
        class="far fa-info-circle ml-1 fa-lg"
        style="color: #61a0ff"
      />
    </b-badge>
  </div>
</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button'

export default {
  name: 'form-field-toggle',
  components: { ToggleButton },
  props: {
    name: String,
    /**
     * Help displayed if exist to assist user
     */
    help: String,
    /**
     * Label displayed when toggle is on true
     */
    label_true: {
      type: String,
      default: ' ',
    },
    /**
     * Label displayed when toggle is on false
     */
    label_false: {
      type: String,
      default: ' ',
    },
    /**
     * To disable the input with a certain condition
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Background color of the toggle when is on true
     */
    color_true: {
      type: String,
      default: '#267C6E',
    },
    /**
     * Background color of the toggle when is on false
     */
    color_false: {
      type: String,
      default: '#e2e2e2',
    },
    /**
     * Value of the toggle
     */
    value: {
      type: Boolean,
    },
    /**
     * Width of the toggle
     */
    width: {
      type: Number,
    },
    /**
     * Fontsize of the toggle label
     */
    font_size: {
      type: Number,
      default: 10,
    },
    /**
     * Height of the toggle
     */
    height: {
      type: Number,
    },
  },
  data: function () {
    return {
      value_: false,
    }
  },
  watch: {
    value: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.value_ = newVal
      }
    },
  },
  mounted() {
    this.value_ = this.value
  },
  methods: {
    changed: function (e) {
      this.$emit('input', e.value)
    },
  },
}
</script>

<style scoped>
div {
  display: inline;
}
</style>
