import { _, isAuthorized, lazyLoadView, piloteAndAuditor } from '@/router/routing'
import type { Route, RouteConfig } from 'vue-router'
import { FeatureFlag } from '@/contexts/shared/feature-flags/FeatureFlags.interfaces'
import { passThroughComponent } from '@/plugins/pass-through-component'

export const securityBaseV2Routes: RouteConfig[] = [
  {
    path: '/security-bases',
    component: () => lazyLoadView(import('@/contexts/security-base-v2/SecurityBaseV2View.vue')),
    meta: {
      i18nTitle: 'security_base_v2.menu.title',
      icon: 'shield-check',
      is_authorized: (route: Route) => {
        return isAuthorized(route)
      },
      requires_auth: true,
      requires_tenant: true,
      requires_role: piloteAndAuditor,
      doc_id: 'security_bases',
      feature_id: 'SECURITY_BASE',
      hide_in_breadcrumb: true,
    },
    children: [
      {
        path: '',
        name: 'security_base_v2_routing',
        component: () => lazyLoadView(import('@/contexts/security-base-v2/SecurityBaseV2RoutingView.vue')),
        meta: {
          i18nTitle: 'security_base_v2.menu.title',
          is_authorized: (route: Route) => isAuthorized(route),
          requires_auth: true,
          requires_tenant: true,
          requires_role: piloteAndAuditor,
          doc_id: 'security_bases',
        },
      },
      {
        path: 'policies',
        component: {
          render: passThroughComponent,
        },
        meta: {
          i18nTitle: 'security_base_v2.menu.objectives',
        },
        children: [
          {
            path: '',
            name: 'security_base_v2_policies',
            component: () => lazyLoadView(import('@/contexts/security-base-v2/SecurityBaseV2PoliciesView.vue')),
            meta: {
              is_authorized: (route: Route) => isAuthorized(route),
              requires_auth: true,
              requires_tenant: true,
              requires_role: piloteAndAuditor,
              doc_id: 'security_bases_policies',
              requires_feature_flag: FeatureFlag.SECURITY_BASE_FUNNEL,
            },
          },
          {
            path: ':policyId/perimeters',
            component: {
              render: passThroughComponent,
            },
            meta: {
              i18nTitle: 'security_base_v2.menu.perimeters',
            },
            children: [
              {
                path: '',
                name: 'security_base_v2_perimeters',
                component: () => lazyLoadView(import('@/contexts/security-base-v2/SecurityBaseV2PerimetersView.vue')),
                meta: {
                  is_authorized: (route: Route) => isAuthorized(route),
                  requires_auth: true,
                  requires_tenant: true,
                  requires_role: piloteAndAuditor,
                  doc_id: 'security_bases_perimeters',
                  requires_feature_flag: FeatureFlag.SECURITY_BASE_FUNNEL,
                },
              },
              {
                path: ':perimeterId/measures',
                component: {
                  render: passThroughComponent,
                },
                meta: {
                  i18nTitle: 'security_base_v2.menu.title',
                },
                children: [
                  {
                    path: '',
                    name: 'security_base_v2_measures_list',
                    component: () =>
                      lazyLoadView(import('@/contexts/security-base-v2/SecurityBaseV2MeasuresListView.vue')),
                    meta: {
                      is_authorized: (route: Route) => isAuthorized(route),
                      requires_auth: true,
                      requires_tenant: true,
                      requires_role: piloteAndAuditor,
                      doc_id: 'security_bases',
                      requires_feature_flag: FeatureFlag.SECURITY_BASE_V2,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      // Old security base integration if FF 'security_base_v2' is off
      {
        path: '/security_base',
        name: 'security_base',
        component: () => lazyLoadView(import('@/contexts/security-base/SecurityBaseView.vue')),
        meta: {
          title: _('Security bases'),
          is_authorized: (route: Route) => {
            return isAuthorized(route)
          },
          requires_auth: true,
          requires_tenant: true,
          requires_role: piloteAndAuditor,
          doc_id: 'security_bases',
          feature_id: 'SECURITY_BASE',
        },
        children: [
          {
            path: 'diff/:id',
            name: 'measures_diff',
            component: () => lazyLoadView(import('@/contexts/security-base/SecurityBaseMeasuresDiffView.vue')),
            meta: {
              title: _('Measures differences'),
              is_authorized: (route: Route) => isAuthorized(route),
              requires_auth: true,
              requires_tenant: true,
              requires_role: piloteAndAuditor,
              doc_id: 'measures_diff',
            },
          },
        ],
      },
    ],
  },
]
