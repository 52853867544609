<template>
  <b-modal
    id="trial-ended-modal"
    hide-footer
    hide-header
    name="trial-ended-modal"
    no-close-on-backdrop
    no-close-on-esc
    size="md"
    @close="closeModal"
  >
    <b-container class="px-3 py-1">
      <b-row class="justify-content-end text-grey"
        ><i
          class="far fa-times close-icon"
          @click="closeModal"
      /></b-row>
      <b-row class="justify-content-center mb-4 bg">
        <h3
          v-if="option_type === 'RISK_ANALYSIS'"
          class="text-tertiary"
        >
          <translate>Your Risks analysis add-on free trial is over</translate>
        </h3>
        <h3
          v-else
          class="text-tertiary"
        >
          <translate>Your Projects security add-on free trial is over</translate>
        </h3>
      </b-row>
      <b-row>
        <p class="mb-4 text-justify">
          <translate>Looking for permanent activation of this add-on within your Tenacy subscription?</translate>
        </p>
        <p class="mb-4 text-justify">
          <translate>
            Contact the support team via the integrated chat or make an appointment with your CSM.
          </translate>
        </p>
      </b-row>
      <b-row class="justify-content-between mb-4">
        <b-button
          class="w-70 text-bold"
          variant="tertiary"
          @click="redirection"
        >
          <span>
            <translate key="add">Book an appointment with your CSM</translate>
          </span>
        </b-button>
        <b-button
          class="wizard-btn w-25"
          @click="dismissOption"
        >
          <span>
            <translate key="add">Dismiss</translate>
          </span>
        </b-button>
      </b-row>
      <b-row class="justify-content-center pl-1 clock-img">
        <img
          alt="Upsell risk analysis "
          class="card-img mt-2"
          :src="`${publicPath}static/img/upsell/clock.svg`"
        />
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
export default {
  name: 'trial-ended-modal',
  props: {
    option_type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      presales_manager: 'elise-chichereau',
      publicPath: import.meta.env.BASE_URL,
    }
  },
  methods: {
    closeModal: function () {
      if (this.option_type === 'RISK_ANALYSIS') {
        this.$bvModal.hide('trial-ended-modal')
        this.$router.push({
          name: 'risks',
        })
      }
      if (this.option_type === 'ISP') {
        this.$bvModal.hide('trial-ended-modal')
      }
    },
    dismissOption: function () {
      this.$api
        .put('customer/customer_option/' + this.$store.getters.getTrialOption(this.option_type).id, {
          dismissed: true,
          type: this.option_type,
        })
        .then(async (resp) => {
          this.$log.debug('DISMISS OPTION', resp)
          if (this.option_type === 'RISK_ANALYSIS') {
            this.$store.commit('set_risk_analysis_enable', false)
          }
          if (this.option_type === 'ISP') {
            this.$store.commit('set_isp_enable', false)
          }
          await this.$store.commit('set_customer_options', resp.data.object)
          this.closeModal()
        })
        .catch((err) => {
          this.$log.debug(err)
        })
    },
    redirection: function () {
      window.open(this.aciso_config.hubspot_url + this.presales_manager, '_blank')
      this.closeModal()
    },
  },
}
</script>

<style scoped>
.clock-img img {
  width: 30%;
  height: 30%;
}
.close-icon {
  color: #c0bebe;
  cursor: pointer;
  font-weight: bold;
}
</style>
