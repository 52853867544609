<template>
  <div class="tw-relative">
    <div class="wrapper tw-absolute tw-inset-0 tw-h-screen tw-bg-white">
      <b-modal
        id="cgu"
        hide-footer
        hide-header
        no-close-on-backdrop
        no-close-on-esc
      >
        <translate>You must accept the terms of use before proceeding to the application.</translate>
        <br />
        <icon-action
          color="primary"
          @click="download_file('user/cgu')"
          ><i class="far fa-file-excel-o" />&nbsp;
          <translate>Download terms of use</translate>
        </icon-action>
        <div class="mt-3 text-center">
          <b-button
            variant="outline-primary"
            @click="acceptCgu()"
          >
            <translate>I accept the terms of use</translate>
          </b-button>
        </div>
      </b-modal>
      <b-modal
        id="send_password_reminder"
        hide-footer
        hide-header
        no-close-on-backdrop
        no-close-on-esc
      >
        <translate>Your password will be expired in</translate>&nbsp;
        <strong v-if="!$store.getters.is_hour"
          >{{ $store.getters.remind_days }}
          <translate>days</translate>
        </strong>

        <strong v-if="$store.getters.is_hour"
          >{{ $store.getters.remind_days }}
          <translate>hours</translate>
        </strong>
        <translate>, you should change it now.</translate>
        <br />
        <div class="mt-3 text-center">
          <b-button
            variant="outline-primary"
            @click="remindLater()"
          >
            <translate>Remind me later</translate>
          </b-button>
          &nbsp;
          <b-button
            variant="outline-primary"
            @click="changePassword()"
          >
            <translate>Change my password</translate>
          </b-button>
        </div>
      </b-modal>
      <main-header ref="mainHeader" />
      <main-sidebar />
      <div
        class="content-wrapper tw-relative tw-bg-white"
        :style="{
          marginTop: getRouteParent() ? '100px' : '60px',
        }"
      >
        <loading
          :active.sync="$store.getters.isLoading"
          :can-cancel="false"
          data-testid="main-spinner"
          :height="32"
          :is-full-page="false"
          :width="32"
          :z-index="1020"
        />
        <section class="content">
          <div
            id="main_content"
            class="container-fluid tw-py-2"
          >
            <router-view ref="current_page" />
          </div>
        </section>
      </div>
      <footer class="main-footer">
        <strong
          >Copyright
          <a
            href="https://www.tenacy.io"
            rel="noopener"
            target="_blank"
            >TENACY {{ $moment().format('YYYY') }}</a
          ></strong
        >. All rights reserved.
        <span class="text-muted">Tenacy</span>

        <span class="pull-right">
          <a
            href="#"
            @click="download_file('user/cgu')"
          >
            <translate>Terms of use</translate>
          </a>
        </span>
      </footer>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.grecaptcha-badge {
  display: none;
}
</style>

<script>
import MainHeader from './layout/main-header.vue'
import MainSidebar from './layout/main-sidebar.vue'
import Loading from 'vue-loading-overlay'
import vv_fr from 'vee-validate/dist/locale/fr'
import { Validator } from 'vee-validate'
import IconAction from './ui/icon-action.vue'
import { setUserEmail } from '@/plugins/survicate'
import intercomService from '@/plugins/Intercom.plugins'
import posthogPlugin from '@/plugins/Posthog.plugins'
import { setI18nLocale } from '@/I18n'

export default {
  name: 'main-app',
  components: {
    IconAction,
    MainHeader,
    MainSidebar,
    Loading,
  },
  data: function () {
    return {
      current_profile: null,
      isDevEnv: this.$store.getters.isDevEnv,
    }
  },
  computed: {
    enable_sub_menu() {
      let max_depth = 4
      if (this.$route.matched.length > 0 && this.$route.matched[0].path == '/nav') {
        max_depth = 5
      }
      return this.$route.matched.length < max_depth
    },
    lang_iso: function () {
      if (this.$store.getters.lang === 'fr') {
        return 'fr_FR'
      } else {
        return 'en_US'
      }
    },
  },
  mounted() {
    if (!this.$store.getters.isDevEnv) {
      posthogPlugin.install()
      posthogPlugin.identifyUser()
    }
    if (this.$store.getters.enable_intercom) {
      const instanceType = this.$store.getters.instance_type
      const isInstanceUser = this.$store.getters.isInstanceUser
      if (
        instanceType === 'business' || // Show for all if business
        (instanceType === 'partner' && isInstanceUser)
      ) {
        // generate hmac for intercom server side
        this.$http.get('/user/generate_hmac').then((resp) => {
          if (resp.data.ok) {
            const hmac = resp.data.object.hmac
            void intercomService.init(hmac)
          }
        })
      }
    }

    if (
      this.aciso_config.enable_survicate &&
      this.aciso_config.survicate_key &&
      this.aciso_config.survicate_key.length > 0
    ) {
      setUserEmail()
    }
    if (this.aciso_config.enable_hubspot && this.isHubspotEnable()) {
      if (window.HubSpotConversations) {
        this.onConversationsAPIReady()
      } else {
        window.hsConversationsOnReady = [this.onConversationsAPIReady]
      }
    }

    $('body').removeClass('login-page')
    if ($.fn['Treeview']) {
      $('[data-widget="treeview"]').Treeview('init')
    }
    $(window).trigger('resize')
    $('.grecaptcha-badge').hide()
    setTimeout(() => {
      $('.grecaptcha-badge').hide()
    }, 500)
    if (!this.aciso_config.is_test) {
      if (!this.$store.getters.cgu_accept) {
        setTimeout(() => {
          this.$bvModal.show('cgu')
        }, 500)
      }
      if (this.$store.getters.send_password_reminder) {
        setTimeout(() => {
          this.$bvModal.show('send_password_reminder')
        }, 500)
      }
    }
  },
  beforeMount() {
    this.$log.debug('INIT LANG ', this.$store.getters.lang)
    setI18nLocale(this.$store.getters.lang)
    this.$language.current = this.lang_iso
    const html = document.documentElement // returns the html tag
    html.setAttribute('lang', this.$store.getters.lang)
    this.$moment.locale(this.$store.getters.lang)
    if (this.$store.getters.lang === 'fr') {
      Validator.localize('fr', vv_fr)
    }
    if (this.$store.getters.authStatus === 'error' && this.$route && this.$route.meta.requires_auth) {
      this.$router.push({ name: 'login' })
    }

    if (this.aciso_config.enable_hubspot) {
      ;(function () {
        window.hsConversationsSettings = {
          loadImmediately: false,
        }
        const d = window.document
        const s = d.createElement('script')
        s.src = '//js-eu1.hs-scripts.com/25190872.js'
        s.async = true
        s.defer = true
        s.id = 'hs-script-loader'
        s.type = 'text/javascript'
        d.getElementsByTagName('head')[0].appendChild(s)
      })()
    }
  },
  methods: {
    onConversationsAPIReady() {
      // use for user identification in hubspot (for the survey, after chat are closed)
      const _hsq = (window._hsq = [])
      _hsq.push(['identify', { email: this.$store.getters.email }])
      _hsq.push(['trackPageView'])
      // use for user identification in hubspot chat
      this.$http.get('auth/hubspot').then((resp) => {
        if (resp.data.ok) {
          window.hsConversationsSettings = {
            identificationEmail: this.$store.getters.email,
            identificationToken: resp.data.token_hubspot,
          }
          setTimeout(() => {
            window.HubSpotConversations.widget.load()
          }, 500)
        }
      })
    },
    acceptCgu() {
      this.$bvModal.hide('cgu')
      this.$http.post('user/cgu_accept').then((resp) => {
        if (resp.data.ok) {
          this.$store.commit('cgu_accept', true)
        }
      })
    },
    remindLater() {
      this.$bvModal.hide('send_password_reminder')
      this.$store.commit('send_password_reminder', false)
    },
    changePassword() {
      setTimeout(() => {
        if (this.$refs.mainHeader) {
          this.$bvModal.hide('send_password_reminder')
          this.$refs.mainHeader.showPasswordChange()
        }
      }, 500)
    },
    getRouteParent() {
      return this.$route.matched.find((route) => route.meta.multi === true) != null && this.enable_sub_menu
    },

    isHubspotEnable() {
      let enable = true
      if (this.$store.getters.instance_type === 'partner') {
        if (!this.$store.getters.isStaffPlus && !this.$store.getters.isStaff && !this.$store.getters.isSupport) {
          enable = false
        }
      } else if (this.$store.getters.instance_type === 'education') {
        if (!this.$store.getters.isStaffPlus && !this.$store.getters.isSupport) {
          enable = false
        }
      }
      // disabled for user where customer_location == None and have no instance role.
      if (
        this.$store.getters.customer_location === this.$store.getters.none_label &&
        !this.$store.getters.isInstanceUser
      ) {
        enable = false
      }
      return this.aciso_config.enable_hubspot && enable
    },
  },
}
</script>

<style scoped lang="scss">
@media (max-width: 1600px) {
  body:not(.sidebar-mini-md) .content-wrapper,
  body:not(.sidebar-mini-md) .main-footer,
  body:not(.sidebar-mini-md) .main-header {
    -webkit-transition: margin-left 0.3s ease-in-out;
    transition: margin-left 0.3s ease-in-out;
    margin-left: 70px !important;
  }
}
</style>
