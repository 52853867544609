import Vue from 'vue'

export const useErrorLogging = () => {
  const apm = Vue.prototype.$apm

  const logError = (error: unknown): void => {
    apm?.captureError(typeof error === 'string' ? new Error(error) : error)
  }

  return { logError }
}
