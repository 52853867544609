import type { RouteConfig } from 'vue-router'
import { lazyLoadView, _ } from '@/router/routing'

export const dashboardShareRoutes: RouteConfig[] = [
  {
    path: '/share/dashboard/:token',
    name: 'dashboard_share',
    component: () => lazyLoadView(import('@/contexts/dashboards/DashboardShareView.vue')),
    meta: {
      title: _('Shared dashboard'),
      requires_auth: false,
    },
  },
]
