import { lazyLoadView, redirectHome } from '@/router/routing'
import { appRoutes } from '@/router/routes/App.routes'
import { errorRoutes } from '@/router/routes/Error.routes'
import { adminRoutes } from '@/router/routes/Admin.routes'
import { authRoutes } from '@/router/routes/Auth.routes'
import { standaloneRoutes } from '@/router/routes/Standalone.routes'
import { navRoutes } from '@/router/routes/nav-routes'
import type { RouterOptions } from 'vue-router/types/router'

import { dashboardShareRoutes } from '@/contexts/dashboards/DashboardShare.routes'
import { policyScoreCoverageRoutes } from '@/contexts/policies/PolicyScoreCoverage.routes'

export const routes: RouterOptions = {
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes: [
    // If path match no one, then load error-404
    { path: '*', component: () => lazyLoadView(import('@/components/unauth-pages/error-404.vue')) },

    // when you call root path ("/") redirect to /home.
    {
      path: '/',
      name: 'main_home',
      redirect: () => redirectHome(),
    },

    ...dashboardShareRoutes,
    ...authRoutes,
    ...appRoutes,
    ...errorRoutes,
    ...navRoutes,
    ...adminRoutes,
    ...standaloneRoutes,

    // TODO DELETE : This is just for testing score engine policy coverage in real environment
    // (SEE https://excube.atlassian.net/browse/ACISO-10296)
    ...policyScoreCoverageRoutes,
  ],
}
