<template>
  <b-modal
    :id="modal_id"
    ref="aciso_tree_modal"
    class="full-screen-modal"
    hide-footer
    size="xl"
    @hidden="$emit('hidden')"
  >
    <template
      v-if="tree_data"
      #modal-title
    >
      <div>
        <b-badge
          v-if="tree_data.label"
          variant="primary"
          >{{ tree_data.label }}</b-badge
        >
        {{ tree_data.name }}
        <template v-if="perimeter">
          - <b-badge variant="primary">{{ perimeter.identifier }}</b-badge>
          <span>{{ perimeter.name }}</span>
        </template>
      </div>
    </template>

    <div>
      <b-row class="justify-content-between">
        <b-col
          v-if="history.length > 1"
          cols="1"
        >
          <template>
            <b-button
              :title="_('Go back')"
              variant="outline-primary"
              @click="navigate(-1)"
            >
              <i class="fa far fa-arrow-left"
            /></b-button>
          </template>
        </b-col>
        <b-col
          class="mh-100 d-flex"
          :cols="history.length > 1 ? 8 : 9"
        >
          <span
            v-if="tree_data && tree_data.type === 'solution'"
            class="tenacy-quick-filter"
          >
            <form-field-checkbox
              v-model="show_all"
              class="ml-2"
              :label_secondary="_('See all')"
              name="show_all"
              :value="show_all"
              @input="getData"
            />
          </span>
          <div
            v-if="show_legend"
            class="legend"
          >
            <b-row>
              <b-col
                v-for="type in Object.keys(type_params)"
                :key="type"
                class="text-sm mt-1"
                :class="{ 'd-none': type_params[type].count === 0 }"
                :cols="'auto'"
                :style="{ 'font-weight': 400 }"
              >
                <div>
                  <span
                    v-if="type !== 'warning'"
                    class="fa-stack"
                  >
                    <i
                      class="fas fa-circle fa-stack-2x"
                      :style="{ color: getColor(type) }"
                    />
                    <i :class="'far fa-' + type_params[type].icon + ' fa-stack-1x fa-inverse'" />
                  </span>
                  <span
                    v-else
                    class="fa-stack"
                  >
                    <i
                      class="far fa-circle fa-stack-2x"
                      :style="{ color: getColor(type) }"
                    />
                  </span>
                  <span
                    class="ml-1"
                    :style="{
                      'font-size': '12px',
                    }"
                  >
                    {{ type_params[type].name }}
                  </span>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col cols="3">
          <b-row class="mb-4 justify-content-end">
            <b-button-group
              size="sm"
              variant="outline-primary"
            >
              <b-button
                :pressed="chart_type === 'force'"
                variant="outline-primary"
                @click="updateType('force')"
                ><translate>Graph</translate></b-button
              >
              <b-button
                :pressed="chart_type === 'radial'"
                variant="outline-primary"
                @click="updateType('radial')"
                ><translate>Radial</translate></b-button
              >
              <b-button
                :pressed="chart_type === 'mindmap'"
                variant="outline-primary"
                @click="updateType('mindmap')"
                ><translate>Mindmap</translate></b-button
              >
            </b-button-group>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <svg
          ref="svg"
          class="measure-tree"
          :class="{ 'tree-loading': loading }"
          :height="svg_height"
          :width="svg_width"
        />
      </b-row>
    </div>
    <div
      v-show="loading"
      class="mt-3 mb-3"
      :style="`text-align: center; position: absolute; top: 40px; left: ${window_width / 2 - 50}px`"
    >
      <i class="far fa-circle-notch fa-2x fa-spin mt-4 text-primary" />
    </div>
  </b-modal>
</template>

<script>
import * as d3 from 'd3'
import FormFieldCheckbox from '../ui/form-field-checkbox.vue'
import { sortPublic, sortValue } from '@/helpers/Sorting.helpers'
import { ICON_DICT } from '@/constants/StyleMixin.constants'
import { getOptionsMixin } from '@/services/GetOptionsMixin.services'
import { textColorFromBg } from '@/helpers/Colors.helpers'
import { scaleColorIndicator } from '@/helpers/Scale.helpers'
import { sanitizeObjectKeys } from '@/helpers/Text.helpers'

let simulation = null

export default {
  name: 'aciso-tree',
  components: { FormFieldCheckbox },
  props: {
    modal_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show_all: false,
      window_height: 700,
      window_width: 1500,
      indicator_scale_options: [],
      show_legend: true,
      d3_tooltip: null,
      perimeter: null,
      tree_data: null,
      current_scale: null,
      chart_type: 'force',
      root_type: null,
      history: [],
      nodes: null,
      links: null,
      links_weight: null,
      nodes_label: null,
      loading: false,
      root_id: null,
      link_font_size: 9,
      node_font_size: 12,
      radius: 14,
    }
  },
  computed: {
    sanitizedTreeData() {
      if (this.tree_data === null) {
        return null
      }

      return sanitizeObjectKeys(this.tree_data, ['name'])
    },
    modal_width() {
      return $('#aciso_tree___BV_modal_body_').width()
    },
    type_params() {
      return {
        gap: {
          color: '#e8561b',
          icon: ICON_DICT['gap'],
          icon_offset: { x: -0.5, y: 1 },
          name: this._('Gap'),
          endpoint: 'gap',
          mindmap_right: ['perimeter', 'gap_register'],
          count: 0,
        },
        gap_register: {
          color: '#ff763b',
          icon: ICON_DICT['gap'],
          icon_offset: { x: -2.5, y: 0.5 },
          name: this._('Gap register'),
          endpoint: 'gap_register',
          mindmap_right: ['gap'],
          count: 0,
        },
        measure: {
          color: '#0A4650',
          icon: ICON_DICT['measure'],
          icon_offset: { x: -1, y: 1 },
          name: this._('Measure'),
          endpoint: 'measure',
          mindmap_right: ['risk', 'policy'],
          count: 0,
        },
        risk: {
          color: '#B52E05',
          icon: ICON_DICT['risk'],
          icon_offset: { x: -0.5, y: 0.5 },
          name: this._('Risk'),
          endpoint: 'risk',
          mindmap_right: ['measure'],
          count: 0,
        },
        solution: {
          color: '#8fc900',
          icon: ICON_DICT['solution'],
          icon_offset: { x: 1, y: 1 },
          name: this._('Measure blueprint'),
          endpoint: 'solution',
          mindmap_right: ['risk', 'policy'],
          count: 0,
        },
        runtask: {
          color: '#f2b847',
          icon: ICON_DICT['runtask'],
          icon_offset: { x: -0.5, y: 0 },
          name: this._('Recurring task'),
          endpoint: null,
          mindmap_right: [],
          count: 0,
        },
        indicator: {
          color: '#FFBA8D',
          icon: ICON_DICT['indicator'],
          icon_offset: { x: -1, y: 0 },
          name: this._('Indicator'),
          endpoint: 'indicator',
          mindmap_right: ['measure'],
          count: 0,
        },
        metric: {
          color: '#FFC1B3',
          icon: ICON_DICT['metric'],
          icon_offset: { x: -1, y: 0 },
          name: this._('Metric'),
          endpoint: null,
          mindmap_right: [],
          count: 0,
        },
        policy: {
          color: '#dfdf53',
          icon: ICON_DICT['policy'],
          icon_offset: { x: -1.5, y: 1 },
          name: this._('Policy'),
          endpoint: 'policy',
          mindmap_right: ['control_group'],
          count: 0,
        },
        control_group: {
          color: '#ACAF1E',
          icon: ICON_DICT['control'],
          icon_offset: { x: -1.5, y: 1 },
          name: this._('Control group'),
          endpoint: 'control_group',
          mindmap_right: ['control'],
          count: 0,
        },
        control: {
          color: '#7B8200',
          icon: ICON_DICT['control'],
          icon_offset: { x: -1.5, y: 1 },
          name: this._('Control'),
          endpoint: 'control',
          mindmap_right: ['solution'],
          count: 0,
        },
        action: {
          color: '#5F0364FF',
          icon: ICON_DICT['action'],
          icon_offset: { x: -1, y: -0.5 },
          name: this._('Action'),
          endpoint: 'remediation',
          mindmap_right: ['perimeter'],
          count: 0,
        },
        action_build: {
          color: '#7e0385',
          icon: ICON_DICT['action_build'],
          icon_offset: { x: -1, y: -0.5 },
          name: this._('Build action'),
          endpoint: 'remediation',
          mindmap_right: ['perimeter'],
          count: 0,
        },
        todo_item: {
          color: '#7e0385',
          icon: ICON_DICT['todo_item'],
          icon_offset: { x: -1, y: -0.5 },
          name: this._('Task'),
          endpoint: null,
          mindmap_right: [],
          count: 0,
        },
        action_upgrade: {
          color: '#84058c',
          icon: ICON_DICT['action_upgrade'],
          icon_offset: { x: -0.5, y: 0 },
          name: this._('Improve action'),
          endpoint: 'remediation',
          mindmap_right: ['perimeter'],
          count: 0,
        },
        exemption: {
          color: '#e8561b',
          icon: ICON_DICT['exemption'],
          icon_offset: { x: -0.5, y: 0.5 },
          name: this._('Exemption'),
          endpoint: null,
          mindmap_right: [],
          count: 0,
        },
        incident: {
          color: '#dc3545',
          icon: ICON_DICT['incident'],
          icon_offset: { x: -0.5, y: 0.5 },
          name: this._('Incident'),
          endpoint: 'incident',
          mindmap_right: [],
          count: 0,
        },
        campaign: {
          color: '#2f1780',
          icon: ICON_DICT['evaluation'],
          icon_offset: { x: -1, y: 0.5 },
          name: this._('Campaign'),
          endpoint: null,
          mindmap_right: [],
          count: 0,
        },
        perimeter: {
          color: '#98d5d7',
          icon: ICON_DICT['single_perimeter'],
          icon_offset: { x: -1.5, y: 0.5 },
          name: this._('Perimeter'),
          endpoint: 'perimeter',
          mindmap_right: ['measure'],
          count: 0,
        },
        consume_perimeter: {
          color: '#98d5d7',
          icon: ICON_DICT['single_perimeter'],
          icon_offset: { x: -1.5, y: 0.5 },
          name: this._('Consuming perimeter'),
          endpoint: 'perimeter',
          mindmap_right: ['measure'],
          count: 0,
        },
        operation_perimeter: {
          color: '#35abb0',
          icon: ICON_DICT['single_perimeter'],
          icon_offset: { x: -1.5, y: 0.5 },
          name: this._('Operating perimeter'),
          endpoint: 'perimeter',
          mindmap_right: ['measure'],
          count: 0,
        },
        parent_grouping: {
          color: '#20c997',
          icon: ICON_DICT['grouping'],
          icon_offset: { x: -1.5, y: 0.5 },
          name: this._('Parent grouping'),
          endpoint: 'tag',
          mindmap_right: ['grouping'],
          count: 0,
        },
        grouping: {
          color: '#278186',
          icon: ICON_DICT['grouping'],
          icon_offset: { x: -1, y: 0.5 },
          name: this._('Grouping'),
          endpoint: 'tag',
          mindmap_right: ['grouping', 'perimeter'],
          count: 0,
        },
        isp_project: {
          color: '#38b050',
          icon: ICON_DICT['project'],
          icon_offset: { x: -1.5, y: 0 },
          name: this._('Project'),
          endpoint: 'isp_project',
          mindmap_right: ['perimeter'],
          count: 0,
        },
        test: {
          color: '#19afd1',
          icon: ICON_DICT['project_test'],
          icon_offset: { x: -1, y: 0.5 },
          name: this._('Test'),
          mindmap_right: ['isp_project'],
          count: 0,
        },
        warning: {
          color: '#ffc107',
          name: this._('Warning'),
          mindmap_right: [],
          count: 0,
        },
      }
    },
    svg_width() {
      return this.window_width
    },
    svg_height() {
      return this.window_height - 50
    },
    max_width() {
      return (this.svg_width - (this.radius + 5) * 2) / 2
    },
    max_height() {
      return (this.svg_height - (this.radius + 5) * 2) / 2
    },
  },
  watch: {},
  mounted: function () {
    const _this = this
    window.addEventListener('resize', () => this.resize())
    $(this.$refs.aciso_tree_modal).on('show.bs.modal', this.resize())
    this.window_width = this.modal_width != null ? this.modal_width : this.window_width

    this.d3_tooltip = d3.select('.d3-tooltip')

    void getOptionsMixin('indicator_scale', true, false).then(({ options }) => {
      _this.indicator_scale_options = options
      _this.indicator_scale_options.sort(sortPublic)
      _this.indicator_scale_options.forEach((scale) => {
        scale.indicator_levels.sort(sortValue)
      })
      if (_this.indicator_scale_options.length > 0) {
        _this.$http.get('/preference/value/performance_scale').then((resp) => {
          if (resp.data.ok) {
            _this.current_scale = _this.indicator_scale_options.find((e) => e.identifier === resp.data.object)
            if (!_this.current_scale) {
              _this.current_scale = _this.indicator_scale_options[0]
            }
          }
        })
      }
    })
  },
  methods: {
    radius_offset(data) {
      if (data.warnings && data.warnings.length > 0) {
        return this.radius + 6
      } else {
        return this.radius + 2
      }
    },
    dragstarted(event, d) {
      if (d.parent === null) {
        return
      }
      if (!event.active) {
        simulation.alphaTarget(0.3).restart()
      }
      d.fx = d.x
      d.fy = d.y
    },
    dragged(event, d) {
      if (d.parent === null) {
        return
      }
      if (event.x < this.max_width && event.x > -this.max_width) {
        d.fx = event.x
      }
      if (event.y < this.max_height && event.y > -this.max_height) {
        d.fy = event.y
      }
    },
    dragended(event, d) {
      if (d.parent === null) {
        return
      }
      if (!event.active) {
        simulation.alphaTarget(0)
      }
      d.fx = null
      d.fy = null
    },
    updateType(type) {
      this.chart_type = type
      this.redrawTree()
    },
    getColor(type) {
      if (this.type_params[type]) {
        return this.type_params[type].color || '#35ABB0'
      }
      return '#35ABB0'
    },
    getIcon(type) {
      if (this.type_params[type]) {
        return this.type_params[type].icon || 'cube'
      }
      return 'help'
    },
    getRadius() {
      return this.radius
    },
    getWarningRadius(data) {
      return data.warnings.length > 0 ? 17 : 0
    },
    releasenode(d) {
      d.fx = null
      d.fy = null
    },
    getValue(d) {
      if (d.score && d.score.value !== null && typeof d.score !== 'undefined' && typeof d.score.value !== 'undefined') {
        let color = null
        if (!d.score.color) {
          color = scaleColorIndicator(d.score.value, this.current_scale)
        } else if (d.score.color !== 'NO_COL') {
          color = '#' + d.score.color
        } else {
          color = '#eeeeee'
        }
        const value = +d.score.value
        if (value === null || isNaN(value) || typeof value === 'undefined') {
          return ''
        }
        return `<span class="badge badge-primary ml-2 badge-tooltip" :title="${
          d.score.tooltip || null
        }" style="background-color: ${color}; color: ${textColorFromBg(color)}">${value.toFixed(0)} ${
          d.score.unit ? d.score.unit : ''
        }</span>`
      }
      return ''
    },
    getWarnings(data) {
      let html = ''
      data.warnings.forEach((w) => {
        html += '<div><i class="far fa-exclamation-triangle" style="color: #ffc107"></i><span> ' + w + '</span></div>'
      })
      return html
    },
    checkParams() {
      if (!this.root_type) {
        this.$log.debug('Not implemented yet')
        return false
      }
      if (!this.type_params[this.root_type].endpoint) {
        this.$log.debug('Not implemented yet')
        return false
      }
      if (!this.root_id) {
        this.$log.debug('Missing root id')
        return false
      }
      return true
    },
    navigate(offset = 0) {
      if (offset === 0) {
        if (!this.checkParams()) {
          return
        }
        this.history.push({ root_id: this.root_id, root_type: this.root_type, perimeter: this.perimeter })
        this.perimeter = null
      } else if (offset < 0 && this.history.length > 1) {
        this.history.pop()
        const e = this.history[this.history.length - 1]
        this.root_id = e.root_id
        this.root_type = e.root_type
        this.perimeter = e.perimeter
        if (!this.checkParams()) {
          return
        }
      } else {
        return
      }
      this.$nextTick(() => {
        this.getData()
      })
    },
    nodeClick(d) {
      if (d.data.id !== this.root_id || this.root_type !== d.data.type) {
        this.root_type = d.data.type
      } else {
        return
      }
      if (this.type_params[this.root_type].endpoint) {
        this.root_id = d.data.id
        this.navigate()
      }
    },
    nodeOver(_el, d) {
      if (!d.data.name) {
        return
      }
      this.d3_tooltip
        .style('opacity', 0.1)
        .html(
          '<span class="badge-tooltip-type">' +
            d.data.name +
            '</span>' +
            ' ' +
            this.getValue(d.data) +
            this.getWarnings(d.data)
        )
        .transition()
        .duration(200)
        .style('opacity', 0.9)
        .style('display', 'block')
        .style('text-align', 'left')

      this.updateTooltip(event, this.d3_tooltip)
    },
    nodeOut() {
      if (this.links) {
        this.links.style('opacity', '100%')
      }
      if (this.nodes) {
        this.nodes.style('opacity', '100%')
      }
      if (this.links_weight) {
        this.links_weight.style('opacity', '100%')
      }
      if (this.nodes_label) {
        this.nodes_label.style('opacity', '100%')
      }
      this.d3_tooltip.transition().duration(100).style('opacity', 0)
    },
    drawRadial(root, svg, width, height) {
      const _this = this
      const diameter = height * 0.75
      const radius = diameter / 2
      const graphGroup = svg.append('g').attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')')

      svg.attr('viewBox', [0, 0, width, height])
      const tree = d3
        .tree()
        .size([2 * Math.PI, radius])
        .separation(function (a, b) {
          return (a.parent === b.parent ? 1 : 2) / a.depth
        })

      if (
        root.children &&
        root.children.length > 0 &&
        root.children.some((e) => e.data.order !== null) &&
        root.data.children.some((e) => e.order !== null)
      ) {
        root.children.sort(function (a, b) {
          return +a.data.order - +b.data.order
        })
        root.data.children.sort(function (a, b) {
          return +a.order - +b.order
        })
      }
      const tree_root = tree(root)
      graphGroup
        .append('g')
        .attr('fill', 'none')
        .attr('stroke-opacity', 0.4)
        .selectAll('path')
        .data(tree_root.links())
        .join('path')
        .attr('stroke-width', (d) => (d.target.data.weight ? Math.max(d.target.data.weight / 20, 1.5) : 1.5))
        .attr('id', (d) => 'tp_' + d.target.data.type + '_' + d.target.data.id)
        .attr(
          'd',
          d3
            .linkRadial()
            .angle((d) => d.x)
            .radius((d) => d.y)
        )
        .attr('stroke', (d) => {
          return _this.getColor(d.target.data.type)
        })

      graphGroup
        .append('g')
        .attr('fill', 'none')
        .selectAll('text')
        .data(tree_root.links())
        .join('text')
        .append('textPath')
        .attr('xlink:href', (d) => '#tp_' + d.target.data.type + '_' + d.target.data.id)
        .attr('startOffset', '50%')
        .attr('fill', 'Black')
        .style('font-size', _this.link_font_size)
        .style('font-weight', 300)
        .text(function (d) {
          return d.target.data.weight ? d.target.data.weight.toFixed(0) : d.target.data.weight
        })

      const node = graphGroup
        .selectAll('.node')
        .data(tree_root.descendants())
        .enter()
        .append('g')
        .attr('class', function (d) {
          return 'node' + (d.children ? ' node--internal' : ' node--leaf')
        })
        .attr(
          'transform',
          (d) => `
        rotate(${(d.x * 180) / Math.PI - 90})
        translate(${d.y},0)
      `
        )
        .on('mouseenter', function (event, d) {
          _this.nodeOver(event, d)
        })
        .on('mouseleave', function () {
          _this.nodeOut()
        })
        .on('click', function (_event, d) {
          _this.nodeClick(d)
        })

      node
        .append('circle')
        .attr('stroke', '#ffc107')
        .attr('fill', 'white')
        .attr('r', (d) => _this.getWarningRadius(d.data))
        .attr('stroke-width', 3)

      node
        .append('circle')
        .attr('fill', (d) => _this.getColor(d.data.type))
        .attr('stroke', 'none')
        .attr('r', (d) => _this.getRadius(d.data))

      node
        .append('svg:foreignObject')
        .attr('width', 25)
        .attr('height', 25)
        .attr(
          'transform',
          (d) => `
        rotate(${(-d.x * 180) / Math.PI + 90})
        translate(${-5 + _this.type_params[d.data.type].icon_offset.x},${
            -12 + _this.type_params[d.data.type].icon_offset.y
          })
      `
        )
        .append('xhtml:body')
        .style('background', 'none')
        .html(
          (d) =>
            '<i class="far fa-' +
            _this.getIcon(d.data.type) +
            ' icon-tree" style="color: ' +
            'white' +
            '; background: none"></i>'
        )

      graphGroup
        .append('g')
        .attr('stroke-linejoin', 'round')
        .attr('stroke-width', 3)
        .selectAll('text')
        .data(tree_root.descendants())
        .join('text')
        .attr(
          'transform',
          (d) => `
        rotate(${(d.x * 180) / Math.PI - 90})
        translate(${d.y},0)
        rotate(${d.x >= Math.PI ? 180 : 0})
      `
        )
        .attr('dy', '0.31em')
        .style('font-weight', 600)
        .attr('font-size', _this.node_font_size)
        .attr('x', (d) => (d.x < Math.PI === !d.children ? _this.radius_offset(d.data) : -_this.radius_offset(d.data)))
        .attr('text-anchor', (d) => (d.x < Math.PI === !d.children ? 'start' : 'end'))
        .text((d) => d.data.label)
        .on('mouseenter', function (event, d) {
          _this.nodeOver(event, d)
        })
        .on('mouseleave', function () {
          _this.nodeOut()
        })
        .on('click', function (_event, d) {
          _this.nodeClick(d)
        })
        .clone(true)
        .lower()
        .attr('stroke', 'white')
    },
    drawMindmapTree(root, pos, svg, width, height) {
      const _this = this
      let SWITCH_CONST = 1
      if (pos === 'left') {
        SWITCH_CONST = -1
      }
      svg.attr('viewBox', [0, 0, width, height])
      const g = svg.append('g').attr('transform', 'translate(' + width / 2 + ',0)')

      const tree = d3.tree().size([height, (SWITCH_CONST * (width - 300)) / 2])

      tree(root)

      const nodes = root.descendants()
      const links = root.links()
      // Set both root nodes to be dead center vertically
      nodes[0].x = height / 2

      // Create links
      const link = g.selectAll('.link').data(links).enter()

      link
        .append('path')
        .attr('class', 'link')
        .attr('fill', 'none')
        .attr('stroke-opacity', 0.4)
        .attr('stroke', (d) => {
          return _this.getColor(d.target.data.type)
        })
        .attr('stroke-width', (d) => (d.target.data.weight ? Math.max(d.target.data.weight / 20, 1.5) : 1.5))
        .attr('id', (d) => 'tp_' + d.target.data.type + '_' + d.target.data.id)
        .attr('d', function (d) {
          return (
            'M' +
            d.source.y +
            ',' +
            d.source.x +
            'C' +
            (d.source.y + d.target.y) / 2.5 +
            ',' +
            d.source.x +
            ' ' +
            (d.source.y + d.target.y) / 2 +
            ',' +
            d.target.x +
            ' ' +
            d.target.y +
            ',' +
            d.target.x
          )
        })

      link
        .append('g')
        .append('text')
        .attr('dy', -4)
        .append('textPath')
        .attr('xlink:href', (d) => '#tp_' + d.target.data.type + '_' + d.target.data.id)
        .attr('startOffset', '50%')
        .style('font-size', _this.link_font_size)
        .style('font-weight', 300)
        .text(function (d) {
          return d.target.data.weight ? d.target.data.weight.toFixed(0) : d.target.data.weight
        })

      // Create nodes
      const node = g
        .selectAll('.node')
        .data(nodes)
        .enter()
        .append('g')
        .attr('class', function (d) {
          return 'node' + (d.children ? ' node--internal' : ' node--leaf')
        })
        .attr('transform', function (d) {
          return 'translate(' + d.y + ',' + d.x + ')'
        })
        .on('mouseenter', function (event, d) {
          _this.nodeOver(event, d)
        })
        .on('mouseleave', function () {
          _this.nodeOut()
        })
        .on('click', function (_event, d) {
          _this.nodeClick(d)
        })
      node
        .append('circle')
        .attr('stroke', '#ffc107')
        .attr('fill', 'white')
        .attr('r', (d) => _this.getWarningRadius(d.data))
        .attr('stroke-width', 3)

      node
        .append('circle')
        .attr('fill', (d) => _this.getColor(d.data.type))
        .attr('stroke', 'none')
        .attr('r', (d) => _this.getRadius(d.data))

      node
        .append('svg:foreignObject')
        .attr('width', 25)
        .attr('height', 25)
        .attr(
          'transform',
          (d) =>
            `translate(${-5 + _this.type_params[d.data.type].icon_offset.x},${
              -12 + _this.type_params[d.data.type].icon_offset.y
            })`
        )
        .append('xhtml:body')
        .style('background', 'none')
        .html(
          (d) =>
            '<i class="far fa-' +
            _this.getIcon(d.data.type) +
            ' icon-tree" style="color: ' +
            'white' +
            '; background: none"></i>'
        )

      node
        .append('text')
        .attr('dy', (d) => (d.children ? '-16px' : '0.31em'))
        .style('font-size', _this.node_font_size)
        .style('font-weight', 600)
        .style('fill', '#222')
        .attr('x', (d) =>
          d.children ? 0 : pos === 'left' ? -_this.radius_offset(d.data) : _this.radius_offset(d.data)
        )
        .style('text-anchor', (d) => (d.children ? 'middle' : pos === 'left' ? 'end' : 'start'))
        .text((d) => (d.data.hide_label ? '' : d.data.label))
        .on('click', function (_event, d) {
          _this.nodeClick(d)
        })
    },
    drawMindmap(_root, svg, width, height) {
      const _this = this
      const data_left = this.copyNestedObjects(this.sanitizedTreeData)
      const data_right = this.copyNestedObjects(this.sanitizedTreeData)
      if (data_right.children.some((e) => e.order !== null)) {
        data_right.children.sort(function (a, b) {
          return +a.order - +b.order
        })
      }
      if (data_left.children.some((e) => e.order !== null)) {
        data_left.children.sort(function (a, b) {
          return +a.order - +b.order
        })
      }
      data_right.hide_label = true
      data_left.children = data_left.children.filter(
        (e) => !_this.type_params[data_left.type].mindmap_right.some((ee) => ee === e.type)
      )
      data_right.children = data_right.children.filter((e) =>
        _this.type_params[data_right.type].mindmap_right.some((ee) => ee === e.type)
      )
      const tree1 = d3.hierarchy(data_left)
      const tree2 = d3.hierarchy(data_right)
      this.drawMindmapTree(tree1, 'left', svg, width, height)
      this.drawMindmapTree(tree2, 'right', svg, width, height)
    },
    drawForce(root, svg, width, height) {
      const links = root.links()
      const nodes = root.descendants()
      nodes.forEach((node) => {
        if (node.parent === null) {
          node.fx = 0
          node.fy = 0
        }
      })
      const _this = this
      simulation = d3
        .forceSimulation(nodes)
        .force(
          'link',
          d3
            .forceLink(links)
            .id((d) => d.id)
            .distance(0)
            .strength(0.3)
        )
        .force('charge', d3.forceManyBody().strength(-400))
        .force('center', d3.forceCenter(0, 0))
        .force(
          'center',
          d3.forceCollide((d) => _this.radius_offset(d.data))
        )

      svg.attr('viewBox', [-width / 2, -height / 2, width, height])

      this.links = svg
        .append('g')
        .attr('fill', 'none')
        .attr('stroke-opacity', 0.4)
        .selectAll('line')
        .data(links)
        .join('line')
        .attr('stroke-width', (d) => (d.target.data.weight ? Math.max(d.target.data.weight / 20, 1.5) : 1.5))
        .attr('stroke', (d) => _this.getColor(d.target.data.type))
        .attr('id', (d) => 'tp_' + d.target.data.type + '_' + d.target.data.id)

      this.links_weight = svg
        .append('g')
        .attr('fill', 'none')
        .attr('stroke', 'null')
        .selectAll('text')
        .data(links)
        .join('text')
        .attr('fill', 'Black')
        .style('font-size', _this.link_font_size)
        .style('font-weight', 300)
        .text((d) => {
          if (d.target.data.show_weight_value === true) {
            return d.target.data.weight ? d.target.data.weight.toFixed(0) : d.target.data.weight
          }
        })

      const drag_handler = d3
        .drag()
        .on('start', function (event, d) {
          _this.dragstarted(event, d)
        })
        .on('drag', function (event, d) {
          _this.dragged(event, d)
        })
        .on('end', function (event, d) {
          _this.dragended(event, d)
        })

      this.nodes = svg
        .append('g')
        .attr('fill', '#fff')
        .attr('stroke', 'none')
        .attr('stroke-width', 1.5)
        .selectAll('node')
        .data(nodes)
        .join('g')
        .attr('class', function (d) {
          return 'node' + (d.children ? ' node--internal' : ' node--leaf')
        })
        .attr('transform', () => 'translate(0,0)')
        .call(drag_handler)
        .on('mouseenter', function (event, d) {
          _this.nodeOver(event, d)
        })
        .on('mouseleave', function () {
          _this.nodeOut()
        })
        .on('click', function (_event, d) {
          _this.nodeClick(d)
        })
      // .on('dblclick', this.releasenode)

      this.nodes
        .append('circle')
        .attr('stroke', '#ffc107')
        .attr('fill', 'white')
        .attr('r', (d) => _this.getWarningRadius(d.data))
        .attr('stroke-width', 3)

      this.nodes
        .append('circle')
        .attr('fill', (d) => _this.getColor(d.data.type))
        .attr('stroke', 'none')
        .attr('r', (d) => _this.getRadius(d.data.type))

      this.nodes
        .append('svg:foreignObject')
        .attr('width', 25)
        .attr('height', 25)
        .attr(
          'transform',
          (d) =>
            `translate(${-5 + _this.type_params[d.data.type].icon_offset.x},${
              -12 + _this.type_params[d.data.type].icon_offset.y
            })`
        )
        .append('xhtml:body')
        .style('background', 'none')
        .html(
          (d) =>
            '<i class="far fa-' +
            _this.getIcon(d.data.type) +
            ' icon-tree" style="color: ' +
            'white' +
            '; background: none"></i>'
        )

      this.nodes
        .append('text')
        .attr('dx', (d) => _this.radius_offset(d.data))
        .attr('dy', '0.31em')
        .style('font-size', _this.node_font_size)
        .style('font-weight', 600)
        .style('fill', '#222')
        .text((d) => d.data.label)

      simulation.on('tick', () => {
        if (!this.nodes) {
          return
        }
        _this.nodes.attr('transform', (d) => {
          if (d.x > _this.max_width) {
            d.x = _this.max_width
          }
          if (d.x < -_this.max_width) {
            d.x = -_this.max_width
          }
          if (d.y > _this.max_height) {
            d.y = _this.max_height
          }
          if (d.y < -_this.max_height) {
            d.y = -_this.max_height
          }
          return 'translate(' + d.x + ',' + d.y + ')'
        })

        this.links
          .attr('x1', (d) => d.source.x)
          .attr('y1', (d) => d.source.y)
          .attr('x2', (d) => d.target.x)
          .attr('y2', (d) => d.target.y)

        this.links_weight.attr(
          'transform',
          (d) => 'translate(' + (d.source.x + d.target.x) / 2 + ',' + (d.source.y + d.target.y) / 2 + ')'
        )
      })
    },
    redrawTree() {
      if (!this.$refs.svg) {
        return
      }
      this.nodes = null
      this.links = null
      this.links_weight = null
      this.nodes_label = null
      const svg = d3.select(this.$refs.svg)
      if (!svg) {
        return
      }
      svg.html('')
      const width = +svg.attr('width')
      const height = +svg.attr('height')

      const root = d3.hierarchy(this.sanitizedTreeData)

      if (this.chart_type === 'radial') {
        this.drawRadial(root, svg, width, height)
      } else if (this.chart_type === 'mindmap') {
        this.drawMindmap(root, svg, width, height)
      } else if (this.chart_type === 'force') {
        this.drawForce(root, svg, width, height)
      }
    },
    resetCounts() {
      const _this = this
      Object.keys(this.type_params).forEach((k) => {
        _this.type_params[k].count = 0
      })
    },
    updateCounts(root) {
      const _this = this
      if (this.type_params[root.type]) {
        this.$set(this.type_params[root.type], 'count', this.type_params[root.type].count + 1)
        if (root.warnings && root.warnings.length > 0) {
          this.$set(this.type_params['warning'], 'count', this.type_params['warning'].count + 1)
        }
      }
      root.children.forEach((c) => {
        _this.updateCounts(c)
      })
    },
    getData() {
      this.tree_data = null
      this.loading = true
      this.d3_tooltip
        .transition()
        .duration(200)
        .style('opacity', 0)
        .on('end', () => this.d3_tooltip.text(''))

      if (this.root_id > 0) {
        const params = {}
        if (this.show_all === true) {
          params.show_all = true
        }
        if (this.perimeter && this.perimeter.id) {
          params.perimeter = this.perimeter.id
        }
        this.$api
          .get('tree/' + this.type_params[this.root_type].endpoint + '/' + this.root_id, { params: params })
          .then((resp) => {
            this.tree_data = resp.data.data
            this.resetCounts()
            this.updateCounts(this.tree_data)
            this.redrawTree()
            this.loading = false
          })
      } else {
        this.loading = false
        this.$toast.alert(this._('Data cannot be loaded'))
        this.$log.debug('Missing object id')
      }
    },
    show(data) {
      this.history = []
      this.root_id = data.id
      this.root_type = data.type
      this.perimeter = data.perimeter
      this.history.push({ root_id: this.root_id, root_type: this.root_type })
      this.$bvModal.show(this.modal_id)
      this.getData()
    },
    resize() {
      const modal_width = $('#aciso_tree___BV_modal_body_').width()
      this.window_width = modal_width != null ? modal_width : 1500
      this.redrawTree()
    },
    updateTooltip(event, tooltip) {
      // Get the mouse position
      const mouseX = event.clientX
      const mouseY = event.clientY

      // Update the tooltip position
      tooltip.style('left', mouseX - tooltip.node().clientWidth / 2 + 'px')
      tooltip.style('top', mouseY - tooltip.node().clientHeight - 10 + 'px') // To place the tooltip above the cursor
    },
  },
}
</script>

<style scoped>
.tree-loading {
  opacity: 0.25 !important;
}

.node {
  cursor: pointer;
}

.node circle {
  fill: #fff;
  stroke: steelblue;
  stroke-width: 1.5px;
}

.node text {
  font: 10px sans-serif;
}

.link {
  fill: none;
  stroke: #ccc;
  stroke-width: 1.5px;
}
</style>
