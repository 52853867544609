<template>
  <div
    :class="{ 'form-group': is_form_group, 'd-inline-flex': inline, 'mh-form': !inline }"
    @click.stop
  >
    <div>
      <label
        v-if="label"
        class="mr-2"
        :style="{ 'font-weight': font_bold ? 'bold' : 'normal' }"
        >{{ label }}</label
      >
      <p-check
        v-model="value_"
        v-b-tooltip.hover
        :checked="checked"
        :class="[
          css_policy_align ? 'p-icon p-fill mr-2 mt-1' : 'p-icon p-fill mr-2 ',
          !checked ? 'disabled-checkbox' : '',
          classes,
        ]"
        :color="color"
        :disabled="disabled"
        :name="name"
        :style="disabled ? 'cursor: not-allowed' : ''"
        :title="help"
        @change="changed"
      >
        <template #extra>
          <i class="icon far fa-check" />
        </template>
        {{ title }}
      </p-check>
      <span
        v-show="label_secondary !== null && label_secondary !== ''"
        class="mr-2"
        >{{ label_secondary }}</span
      >
    </div>
  </div>
</template>
<script>
/**
 * UI component field checkbox
 * @public
 */
export default {
  name: 'form-field-checkbox',
  props: {
    name: String,
    font_bold: {
      type: Boolean,
      default: true,
    },
    is_form_group: {
      type: Boolean,
      default: true,
    },
    /**
     * Value used to init the checkbox
     */
    checked: {
      type: Boolean,
      default: false,
    },
    /**
     * Additional css classes
     */
    classes: {
      type: String,
      default: '',
    },
    /**
     * Enable align
     */
    css_policy_align: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    label: String,
    label_secondary: String,
    help: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    title: String,
    color: {
      type: String,
      default: 'primary',
    },
    value: {
      type: Boolean,
    },
  },
  data: function () {
    return {
      value_: false,
    }
  },
  watch: {
    value: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.value_ = newVal
      }
    },
  },
  mounted() {
    this.value_ = this.value
  },
  methods: {
    /**
     * Emit new value @checked
     */
    changed: function (e) {
      this.$emit('input', e)
    },
  },
}
</script>
