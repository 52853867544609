import { _, allRoles, isAuthorized, lazyLoadView } from '@/router/routing'
import type { Route, RouteConfig } from 'vue-router'

const legacyRoutes: RouteConfig[] = [
  {
    path: 'ui-scale',
    name: 'ui-scale',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-scale.vue')),
    meta: {
      title: 'Scales',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-scale',
    },
  },
  {
    path: 'ui-slider',
    name: 'ui-slider',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-slider.vue')),
    meta: {
      title: 'Slider',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-slider',
    },
  },
  {
    path: 'ui-form-field',
    name: 'ui-form-field',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-form-field.vue')),
    meta: {
      title: 'Form Field',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-form-field',
    },
  },
  {
    path: 'ui-viewer-sanitize',
    name: 'ui-viewer-sanitize',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-viewer-sanitize.vue')),
    meta: {
      title: 'ViewerSanitize',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-viewer-sanitize',
    },
  },
]

const ndsRoutes: RouteConfig[] = [
  {
    path: 'ui-nds-button',
    name: 'ui-nds-button',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-button.vue')),
    meta: {
      title: 'NdsButton',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-button',
    },
  },
  {
    path: 'ui-nds-table',
    name: 'ui-nds-table',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-table.vue')),
    meta: {
      title: 'NdsTable',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-table',
    },
  },
  {
    path: 'ui-nds-icon',
    name: 'ui-nds-icon',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-icon.vue')),
    meta: {
      title: 'NdsIcon',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-icon',
    },
  },
  {
    path: 'ui-nds-modal',
    name: 'ui-nds-modal',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-modal.vue')),
    meta: {
      title: 'NdsModal',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-modal',
    },
  },
  {
    path: 'ui-nds-spinner',
    name: 'ui-nds-spinner',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-spinner.vue')),
    meta: {
      title: 'NdsSpinner',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-spinner',
    },
  },
  {
    path: 'ui-nds-input-radio',
    name: 'ui-nds-input-radio',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-input-radio.vue')),
    meta: {
      title: 'NdsInputRadio',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-input-radio',
    },
  },
  {
    path: 'ui-nds-drop-down',
    name: 'ui-nds-drop-down',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-drop-down.vue')),
    meta: {
      title: 'NdsDropDown',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-drop-down',
    },
  },
  {
    path: 'ui-nds-input',
    name: 'ui-nds-input',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-input.vue')),
    meta: {
      title: 'NdsInput',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-input',
    },
  },
  {
    path: 'ui-nds-input-label',
    name: 'ui-nds-input-label',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-input-label.vue')),
    meta: {
      title: 'NdsInputLabel',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-input-label',
    },
  },
  {
    path: 'ui-nds-date-picker',
    name: 'ui-nds-date-picker',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-date-picker.vue')),
    meta: {
      title: 'NdsDatePicker',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-date-picker',
    },
  },
  {
    path: 'ui-nds-pagination',
    name: 'ui-nds-pagination',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-pagination.vue')),
    meta: {
      title: 'NdsPagination',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-pagination',
    },
  },
  {
    path: 'ui-nds-card',
    name: 'ui-nds-card',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-card.vue')),
    meta: {
      title: 'NdsCard',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-card',
    },
  },
  {
    path: 'ui-nds-progress-bar',
    name: 'ui-nds-progress-bar',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-progress-bar.vue')),
    meta: {
      title: 'NdsProgressBar',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-progress-bar',
    },
  },
  {
    path: 'ui-nds-stepper',
    name: 'ui-nds-stepper',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-stepper.vue')),
    meta: {
      title: 'NdsStepper',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-stepper',
    },
  },
  {
    path: 'ui-nds-input-checkbox',
    name: 'ui-nds-input-checkbox',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-input-checkbox.vue')),
    meta: {
      title: 'NdsInputCheckbox',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-input-checkbox',
    },
  },
  {
    path: 'ui-nds-textarea',
    name: 'ui-nds-textarea',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-textarea.vue')),
    meta: {
      title: 'NdsTextarea',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-textarea',
    },
  },
  {
    path: 'ui-nds-answer-button',
    name: 'ui-nds-answer-button',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-answer-button.vue')),
    meta: {
      title: 'NdsAnswerButton',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-answer-button',
    },
  },
  {
    path: 'ui-nds-tree-view',
    name: 'ui-nds-tree-view',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-tree-view.vue')),
    meta: {
      title: 'NdsTreeView',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-tree-view',
    },
  },
  {
    path: 'ui-nds-alert',
    name: 'ui-nds-alert',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-alert.vue')),
    meta: {
      title: 'NdsAlert',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-alert',
    },
  },
  {
    path: 'ui-nds-comment',
    name: 'ui-nds-comment',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-comment.vue')),
    meta: {
      title: 'NdsComment',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-comment',
    },
  },
  {
    path: 'ui-nds-score-badge',
    name: 'ui-nds-score-badge',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-score-badge.vue')),
    meta: {
      title: 'NdsScoreBadge',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-score-badge',
    },
  },
  {
    path: 'ui-nds-score-answer-badge',
    name: 'ui-nds-score-answer-badge',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-score-answer-badge.vue')),
    meta: {
      title: 'NdsScoreAnswerBadge',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-score-answer-badge',
    },
  },
  {
    path: 'ui-nds-score',
    name: 'ui-nds-score',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-score.vue')),
    meta: {
      title: 'NdsScore',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-score',
    },
  },
  {
    path: 'ui-nds-score-range',
    name: 'ui-nds-score-range',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-score-range.vue')),
    meta: {
      title: 'NdsScoreRange',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-score-range',
    },
  },
  {
    path: 'ui-nds-score-info',
    name: 'ui-nds-score-info',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-score-info.vue')),
    meta: {
      title: 'NdsScoreInfo',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-score-info',
    },
  },
  {
    path: 'ui-nds-tooltip',
    name: 'ui-nds-tooltip',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-tooltip.vue')),
    meta: {
      title: 'NdsTooltip',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-tooltip',
    },
  },
  {
    path: 'ui-nds-option',
    name: 'ui-nds-option',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-option.vue')),
    meta: {
      title: 'NdsOption',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-option',
    },
  },
  {
    path: 'ui-nds-upload-file',
    name: 'ui-nds-upload-file',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-upload-file.vue')),
    meta: {
      title: 'NdsUploadFile',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-upload-file',
    },
  },
  {
    path: 'ui-nds-panel',
    name: 'ui-nds-panel',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-panel.vue')),
    meta: {
      title: 'NdsPanel',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-panel',
    },
  },
  {
    path: 'ui-nds-tag',
    name: 'ui-nds-tag',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-tag.vue')),
    meta: {
      title: 'NdsTag',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-tag',
    },
  },
  {
    path: 'ui-nds-form-input',
    name: 'ui-nds-form-input',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-form-input.vue')),
    meta: {
      title: 'NdsFormInput',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-form-input',
    },
  },
  {
    path: 'ui-nds-markdown-editor',
    name: 'ui-nds-markdown-editor',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-markdown-editor.vue')),
    meta: {
      title: 'NdsMarkdownEditor',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-markdown-editor',
    },
  },
  {
    path: 'ui-nds-markdown-viewer',
    name: 'ui-nds-markdown-viewer',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-markdown-viewer.vue')),
    meta: {
      title: 'NdsMarkdownViewer',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-markdown-viewer',
    },
  },
  {
    path: 'ui-nds-chip',
    name: 'ui-nds-chip',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-chip.vue')),
    meta: {
      title: 'NdsChip',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-chip',
    },
  },
  {
    path: 'ui-nds-chip-selector',
    name: 'ui-nds-chip-selector',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-chip-selector.vue')),
    meta: {
      title: 'NdsChipSelector',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-chip-selector',
    },
  },
  {
    path: 'ui-nds-tab',
    name: 'ui-nds-tab',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-tab.vue')),
    meta: {
      title: 'NdsTab',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-tab',
    },
  },
  {
    path: 'ui-nds-tab-bar',
    name: 'ui-nds-tab-bar',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-tab-bar.vue')),
    meta: {
      title: 'NdsTabBar',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-tab-bar',
    },
  },
  {
    path: 'ui-nds-input-select',
    name: 'ui-nds-input-select',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-input-select.vue')),
    meta: {
      title: 'NdsInputSelect',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-input-select',
    },
  },
  {
    path: 'ui-nds-input-multi-select',
    name: 'ui-nds-input-multi-select',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-input-multi-select.vue')),
    meta: {
      title: 'NdsInputMultiSelect',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-input-multi-select',
    },
  },
  {
    path: 'ui-nds-heading',
    name: 'ui-nds-heading',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-heading.vue')),
    meta: {
      title: 'NdsHeading',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-heading',
    },
  },
  {
    path: 'ui-nds-text',
    name: 'ui-nds-text',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-text.vue')),
    meta: {
      title: 'NdsText',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-text',
    },
  },
  {
    path: 'ui-nds-text-overflow',
    name: 'ui-nds-text-overflow',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-text-overflow.vue')),
    meta: {
      title: 'NdsTextOverflow',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-text-overflow',
    },
  },
]

const pluginsRoutes: RouteConfig[] = [
  {
    path: 'ui-i18n',
    name: 'ui-i18n',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-i18n.vue')),
    meta: {
      title: 'I18n',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-i18n',
    },
  },
  {
    path: 'ui-nds-toast',
    name: 'ui-nds-toast',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-toast.vue')),
    meta: {
      title: 'Toast plugin',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-nds-toast',
    },
  },
  {
    path: 'ui-use-error-logging',
    name: 'ui-use-error-logging',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-use-error-logging.vue')),
    meta: {
      title: 'UseErrorLogging',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-i18n',
    },
  },
  {
    path: 'ui-use-api',
    name: 'ui-use-api',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-use-api.vue')),
    meta: {
      title: 'UseApi',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-use-api',
    },
  },
  {
    path: 'ui-use-search',
    name: 'ui-use-search',
    component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-use-search.vue')),
    meta: {
      title: 'UseSearch',
      is_authorized: (route: Route) => isAuthorized(route),
      requires_auth: true,
      requires_tenant: true,
      requires_role: allRoles,
      doc_id: 'ui-use-search',
    },
  },
]

export const uiKitRoutes: RouteConfig = {
  path: '/ui-kit',
  component: () => lazyLoadView(import('@/components/ui-kit/ui-kit.vue')),
  meta: {
    title: _('UI kit'),
    icon: 'toolbox',
    is_authorized: (route: Route) => isAuthorized(route),
    requires_auth: true,
    requires_tenant: true,
    requires_role: allRoles,
  },
  children: [
    {
      path: '',
      name: 'ui-kit',
      component: () => lazyLoadView(import('@/components/ui-kit/ui-kit-home.vue')),
      meta: {
        is_authorized: (route: Route) => isAuthorized(route),
        requires_auth: true,
        requires_tenant: true,
        requires_role: allRoles,
        doc_id: 'home',
      },
    },
    ...legacyRoutes,
    ...ndsRoutes,
    ...pluginsRoutes,
  ],
}
