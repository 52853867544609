import {
  _,
  allRoles,
  beforeRiskAnalysis,
  hasOption,
  isAuthorized,
  lazyLoadView,
  piloteAndAuditor,
  requiresInternal,
  setNavL2,
} from '@/router/routing'
import type { Route, RouteConfig } from 'vue-router'
import MainContent from '@/components/layout/main-content.vue'

export const risksRoutes: RouteConfig = {
  path: '/risks',
  name: 'risks_catalog_registers',
  component: MainContent,
  meta: {
    title: _('Risks'),
    icon: 'radiation-alt',
    alt_title: _('Risks'),
    multi: true,
    dummy: true,
    requires_auth: true,
    requires_tenant: true,
    requires_role: piloteAndAuditor,
    hidden_for_fp_assessment: true,
    for_fp_assessment_risk_analysis: true,
    is_authorized: (route: Route) => isAuthorized(route),
    type: 'RISK_ANALYSIS',
    feature_id: 'RISKS',
  },
  children: [
    {
      path: 'analysis',
      name: 'business-value-register',
      component: () => lazyLoadView(import('@/contexts/risks/BusinessValueRegistersListView.vue')),
      beforeEnter: setNavL2,
      meta: {
        title: _('Analysis'),
        icon: 'radiation-alt',
        is_authorized: (route: Route) => isAuthorized(route),
        hidden_for_fp_assessment: true,
        for_fp_assessment_risk_analysis: true,
        requires_auth: true,
        requires_tenant: true,
        requires_role: piloteAndAuditor,
        doc_id: 'business_value_register',
        feature_id: 'RISKS',
      },
      children: [
        {
          path: ':id',
          name: 'risk-analysis',
          component: () => lazyLoadView(import('@/contexts/risks/BusinessValueRiskAnalysisView.vue')),
          beforeEnter: beforeRiskAnalysis,
          meta: {
            title: null,
            icon: 'radiation-alt',
            is_authorized: (route: Route) => isAuthorized(route),
            hidden_for_fp_assessment: true,
            for_fp_assessment_risk_analysis: true,
            requires_auth: true,
            requires_tenant: true,
            requires_role: piloteAndAuditor,
            doc_id: 'risk_analysis',
            feature_id: 'RISKS',
          },
        },
      ],
    },
    {
      path: 'registers',
      name: 'risks',
      component: () => lazyLoadView(import('@/contexts/risks/RiskRegistersListView.vue')),
      beforeEnter: setNavL2,
      meta: {
        title: _('Treatment'),
        icon: 'radiation-alt',
        is_authorized: (route: Route) => isAuthorized(route),
        hidden_for_fp_assessment: true,
        for_fp_assessment_risk_analysis: true,
        requires_auth: true,
        requires_tenant: true,
        requires_role: piloteAndAuditor,
        doc_id: 'risk_registers',
        feature_id: 'RISKS',
      },
      children: [
        {
          path: ':id',
          name: 'risk',
          component: () => lazyLoadView(import('@/contexts/risks/RiskRegisterView.vue')),

          meta: {
            title: null,
            icon: 'radiation-alt',
            is_authorized: (route: Route) => isAuthorized(route),
            hidden_for_fp_assessment: true,
            for_fp_assessment_risk_analysis: true,
            requires_auth: true,
            requires_tenant: true,
            requires_role: piloteAndAuditor,
            doc_id: 'risk_register',
            feature_id: 'RISKS',
          },
        },
      ],
    },
    {
      path: 'catalog',
      name: 'catalog-registers',
      component: () => lazyLoadView(import('@/contexts/risks/RisksCatalogView.vue')),
      beforeEnter: setNavL2,
      meta: {
        title: _('Catalog'),
        icon: 'radiation-alt',
        is_optional: false,
        has_option: () => hasOption('risk_analysis'),
        is_authorized: (route: Route) => isAuthorized(route),
        hidden_for_fp_assessment: true,
        for_fp_assessment_risk_analysis: true,
        requires_auth: true,
        requires_tenant: true,
        requires_role: piloteAndAuditor,
        doc_id: 'risk_catalog',
        feature_id: 'RISKS',
      },
    },
  ],
}

export const risksContributionRoutes: RouteConfig = {
  path: 'risks',
  name: 'myrisks',
  component: () => lazyLoadView(import('@/contexts/risks/RisksContributionView.vue')),
  beforeEnter: setNavL2,
  meta: {
    title: _('Risks'),
    icon: 'radiation-alt',
    is_authorized: (route: Route) => isAuthorized(route),
    requires_auth: true,
    requires_tenant: true,
    hidden_for_fp_assessment: true,
    for_fp_assessment_risk_analysis: true,
    requires_role: allRoles,
    requires_internal: () => requiresInternal(),
    doc_id: 'my_risks',
    nb_contribution: 'nb_risks',
  },
}
