import { NdsColor } from '@/interfaces/NdsColors.interfaces'

export enum NdsButtonSize {
  L = 'large',
  M = 'medium',
  S = 'small',
  XS = 'xSmall',
}

export enum NdsButtonColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  TRANSPARENT = 'transparent',
  DESTRUCTIVE = 'destructive',
}

export const NdsButtonLoaderColorMapping: Readonly<Record<NdsButtonColor, NdsColor>> = {
  [NdsButtonColor.PRIMARY]: NdsColor.WHITE,
  [NdsButtonColor.SECONDARY]: NdsColor.NEUTRAL_950,
  [NdsButtonColor.TERTIARY]: NdsColor.NEUTRAL_950,
  [NdsButtonColor.TRANSPARENT]: NdsColor.NEUTRAL_950,
  [NdsButtonColor.DESTRUCTIVE]: NdsColor.DANGER_500,
}

export enum NdsButtonType {
  BUTTON = 'button',
  SUBMIT = 'submit',
}
