import type { Route, RouteConfig } from 'vue-router'
import { _, isAuthorized, lazyLoadView, piloteAndAuditor, setNavL2 } from '@/router/routing'
import MainContent from '@/components/layout/main-content.vue'

export const policiesRoutes: RouteConfig = {
  path: '/policies',
  name: 'policies_questionnaires',
  component: MainContent,
  meta: {
    title: _('Policies'),
    alt_title: _('Policies and questionnaires'),
    icon: 'books',
    multi: true,
    dummy: true,
    requires_auth: true,
    requires_tenant: true,
    requires_role: piloteAndAuditor,
    is_authorized: (route: Route) => isAuthorized(route),
    feature_id: 'POLICIES',
  },
  children: [
    {
      path: 'policies',
      name: 'policies',
      component: () => lazyLoadView(import('@/contexts/policies/PoliciesView.vue')),
      beforeEnter: setNavL2,
      meta: {
        title: _('Policies'),
        icon: 'books',
        is_authorized: (route: Route) => isAuthorized(route),
        requires_auth: true,
        requires_tenant: true,
        hide_in_breadcrumb: true,
        requires_role: piloteAndAuditor,
        doc_id: 'policy',
        feature_id: 'POLICIES',
      },
      children: [
        {
          path: ':id',
          name: 'policy_version',
          component: () => lazyLoadView(import('@/contexts/policies/PolicyVersionView.vue')),
          props: (route: Route) => ({ perimeter: route.query.p, target_score: route.query.ts }),
          meta: {
            title: null,
            requires_auth: true,
            requires_tenant: false,
            sidebar: false,
            is_authorized: (route: Route) => isAuthorized(route),
            requires_role: piloteAndAuditor,
            doc_id: 'policy_details',
            feature_id: 'POLICIES',
          },
        },
      ],
    },
    {
      path: 'questionnaires',
      name: 'questionnaires',
      component: () => lazyLoadView(import('@/contexts/policies/PoliciesQuestionnairesView.vue')),
      beforeEnter: setNavL2,
      meta: {
        title: _('Questionnaires'),
        icon: 'file-alt',
        is_authorized: (route: Route) => isAuthorized(route),
        requires_auth: true,
        requires_tenant: true,
        hide_in_breadcrumb: true,
        requires_role: piloteAndAuditor,
        doc_id: 'questionnaires',
        feature_id: 'CAMPAIGNS',
      },
      children: [
        {
          path: ':id/results',
          name: 'questionnaire-results',
          component: () => lazyLoadView(import('@/contexts/policies/PoliciesQuestionnaireResultsView.vue')),
          meta: {
            title: null,
            is_authorized: (route: Route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: false,
            requires_role: piloteAndAuditor,
            feature_id: 'CAMPAIGNS',
            doc_id: 'questionnaire-results',
          },
        },
        {
          path: ':id',
          name: 'questionnaire',
          component: () => lazyLoadView(import('@/contexts/policies/PoliciesQuestionnaireView.vue')),
          meta: {
            title: null,
            is_authorized: (route: Route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: false,
            requires_role: piloteAndAuditor,
            doc_id: 'questionnaire',
          },
        },
      ],
    },
  ],
}
