<template>
  <b-modal
    id="modalReview"
    hide-footer
    size="xl"
    :title="modal_review_title"
  >
    <review-table
      ref="reviews"
      :api_endpoint_="api_endpoint()"
      @close-modal="closeModal"
      @close-target="closeTarget($event)"
    />
  </b-modal>
</template>

<script>
import ReviewTable from '@/components/ui/review-table.vue'
export default {
  name: 'modal-review',
  components: { ReviewTable },

  data: function () {
    return {
      source_obj: null,
      source: null,
    }
  },

  computed: {
    modal_review_title() {
      if (this.source === 'REMEDIATION') {
        return this._('Action reviews')
      } else {
        return this._('Reviews')
      }
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('modalReview')
      if (this.$route.name === 'modeling') {
        this.$root.$emit('hideModalReview')
      }
    },
    api_endpoint() {
      if (this.source === 'REMEDIATION' && this.source_obj) {
        return 'remediation/' + this.source_obj.id + '/review'
      } else {
        return 'review'
      }
    },
    showReview(data) {
      this.$log.debug('DATA REVIEW', data)
      this.source_obj = data.source_obj
      this.source = data.source
      this.$bvModal.show('modalReview')
    },
    closeTarget(event) {
      if (this.$route.name === 'incidents' && event.incident_id) {
        this.$root.$emit('closeIncident', event.incident_id)
      }
      if (this.$route.name === 'exemptions' && event.exemption_id) {
        this.$root.$emit('closeExemption', event.exemption_id)
      }
      if (this.$route.name === 'gap' && event.gap_id) {
        this.$root.$emit('closeGap', event.gap_id)
      }
      if (this.$route.name === 'project' && event.isp_project_id) {
        this.$root.$emit('closeProject', event.isp_project_id)
      }
    },
  },
}
</script>
