<template>
  <b-modal
    v-show="current_next_step"
    :id="modal_id"
    hide-footer
    :title="_('Project step change')"
    @hidden="$emit('hidden')"
  >
    <form :data-vv-scope="scope">
      <b-row v-if="current_next_step && current_next_step.order === 8 && actions_not_finished">
        <b-col>
          <i class="far fa-exclamation-circle text-warning" />
          {{ warning_action_message }}
        </b-col>
      </b-row>
      <b-row v-if="current_next_step && current_next_step.order === 8 && campaigns_not_finished">
        <b-col>
          <i class="far fa-exclamation-circle text-warning" />
          {{ warning_campaign_message }}
        </b-col>
      </b-row>
      <b-row
        align-h="center"
        class="mt-3"
      >
        <b-col cols="12">
          <form-field-date
            v-if="current_next_step && show_end_date && current_obj && current_obj.current_step"
            ref="target_date"
            v-model="current_next_step.end_date"
            v-validate="
              current_obj.current_step.order > 1 && current_next_step.previous_date != null
                ? {
                    required: true,
                    date_format: 'dd/MM/yyyy',
                    after: [current_next_step.previous_date, 'inclusion'],
                    before: [current_date, 'inclusion'],
                  }
                : { required: true, date_format: 'dd/MM/yyyy', before: [current_date, 'inclusion'] }
            "
            :allow_empty="false"
            data-vv-validate-on="change|blur"
            :error="errors.first(scope + '.end_date')"
            :label="
              $store.getters.lang === 'fr'
                ? 'Date de fin de la mise en' + ' ' + current_obj.current_step.name_fr + ' ' + '*'
                : 'End date of' + ' ' + current_obj.current_step.name_en + ' ' + 'launch *'
            "
            name="end_date"
          />
        </b-col>
      </b-row>
    </form>
    <b-button
      v-if="current_next_step && current_obj && current_obj.current_step"
      class="pull-right"
      variant="outline-primary"
      @click="nextStep(current_next_step)"
    >
      {{
        current_next_step.order === 8
          ? _('Close project')
          : current_next_step.order > current_obj.current_step.order
          ? _('Go to the next step')
          : _('Go to the previous step')
      }}
    </b-button>
  </b-modal>
</template>

<script>
import FormFieldDate from '@/components/ui/form-field-date.vue'

export default {
  name: 'isp-project-transition-modal',
  components: { FormFieldDate },
  props: {
    modal_id: String,
    current_next_step: Object,
    current_obj: Object,
    show_end_date: Boolean,
    scope: String,
    actions_not_finished: Boolean,
    campaigns_not_finished: Boolean,
    current_date: String,
  },
  computed: {
    warning_action_message() {
      return this._(
        'There are still ongoing actions associated to this project, if you close it you wont be able to update them anymore.'
      )
    },
    warning_campaign_message() {
      return this._(
        'There are still ongoing campaigns associated to this project, if you close it will automatically submit completed ones and ignore the others.'
      )
    },
  },
  methods: {
    nextStep(next_step) {
      this.$emit('to-next-step', next_step)
    },
  },
}
</script>
