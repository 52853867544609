<template>
  <div
    v-if="show_field || show_input"
    :class="'form-group ' + fg_classes"
  >
    <label
      v-if="label"
      :class="{ 'mb-0': !disabled && error }"
      :style="{ fontWeight: is_bold ? 500 : 400 }"
      >{{ label_ }}&nbsp;
      <b-badge
        v-if="enable_select_all && !disabled"
        class="link noBackgroundSelectButton"
        @click="$emit('select_all')"
      >
        {{ _('select all') }}
      </b-badge>
      <b-badge
        v-if="enable_select_none && !disabled"
        class="link noBackgroundSelectButton"
        @click="selectNone"
      >
        {{ _('deselect all') }}
      </b-badge>
      <slot name="label" />
    </label>
    <b-badge
      v-if="help && help.length > 0"
      v-b-tooltip
      class="pull-right mt-1"
      :title="help"
      variant="default"
    >
      <i
        class="far fa-info-circle ml-1 fa-lg"
        style="color: #61a0ff"
      />
    </b-badge>
    <div
      v-if="error"
      class="error-container"
    >
      <span class="invalid-feedback">{{ invalidFeedbackErrorMessage }}</span>
    </div>
    <div
      v-if="tag_warning"
      class="error-container invalid-feedback"
    >
      <translate>4-128 chars, only digits, space, letters (with accent) and -_/\() are allowed</translate>
    </div>
    <multiselect
      v-if="!disabled || show_input"
      :allow-empty="allow_empty"
      class="multi-select"
      :class="{
        'is-invalid': !!error,
        'is-valid': !error && !disabled && touched,
        'centered-option': centered,
        'over-block': over_block,
      }"
      :close-on-select="closeOnSelect"
      :confirm-delete="confirmDelete"
      :disabled="disabled"
      :group-label="groupLabel"
      :group-select="groupSelect"
      :group-values="groupValues"
      :hide-selected="hide_selected"
      :internal-search="!(search_identifier || remote_search)"
      :label="options_label"
      :loading="is_loading"
      :max-height="max_height"
      :multiple="!!multiple"
      :name="name"
      :options="search_identifier ? options_ : options"
      :options-limit="6000"
      :placeholder="returnPlaceholder(placeholder)"
      :show-labels="false"
      :tag-placeholder="returnTagPlaceholder(tagPlaceholder)"
      :taggable="taggable"
      :track-by="trackby"
      :v-model="vModel"
      :value="value"
      @input="changed"
      @remove="remove"
      @search-change="findNameIdentifier"
      @tag="addTag"
    >
      <template #noOptions>{{ _('No entry in the list') }}</template>
      <template #noResult>{{ _('No element found') }}</template>
      <template #maxElements>{{ _('Maximum number of options selected') }}</template>

      <template #singleLabel="{ option }">
        <span
          v-if="option && is_perimeter"
          class="option__title"
        >
          <i
            v-if="option && option.id > 0"
            :class="option_title_fa_icons[option.type]"
          />
          {{ option.name | truncate(40) }}
        </span>
        <span
          v-else-if="option && grp_perimeters"
          class="option__title"
        >
          <i
            v-if="option && option.id < 0"
            class="far fa-cubes fa-lg mr-1"
          />
          <i
            v-if="option && option.id > 0"
            class="far fa-cube mr-1"
          />
          {{ option.name | truncate(40) }}
        </span>
        <span
          v-else-if="option && dfs_perimeters"
          class="option__title"
        >
          <i
            v-if="(option.tag && option.is_root) || option.id === 0"
            class="far fa-sitemap mr-1"
            :style="option.tag !== $store.getters.customer ? 'color:#0A4650;' : ''"
          />
          <i
            v-else-if="!option.tag && option.type === 'APPLICATION'"
            class="fab fa-angular fa-lg mr-1"
          />
          <i
            v-else-if="!option.tag && option.type === 'PROVIDER'"
            class="far fa-people-arrows mr-1"
          />
          <i
            v-else-if="option.id < 0"
            class="far fa-cubes fa-lg mr-1"
          />
          <i
            v-else
            class="far fa-cube mr-1"
          />
          {{ option.name | truncate(40) }}
        </span>
      </template>

      <template #option="{ option }">
        <span
          v-if="option && is_perimeter"
          class="option__title"
        >
          <i
            v-if="option && option.id > 0 && option.type === 'INTERNAL'"
            class="far fa-cube mr-2"
          />
          <i
            v-else-if="option && option.id > 0 && option.type === 'APPLICATION'"
            class="fab fa-angular fa-lg mr-2"
          />
          <i
            v-else-if="option && option.id > 0 && option.type === 'PROVIDER'"
            class="far fa-people-arrows mr-2"
          />
          {{ option.name | truncate(40) }}
        </span>
        <span
          v-else-if="option && grp_perimeters"
          class="option__title"
        >
          <i
            v-if="option && option.id < 0"
            class="far fa-cubes mr-2"
          />
          <i
            v-if="option && option.id > 0"
            class="far fa-cube mr-2"
          />
          {{ option.name | truncate(40) }}
        </span>
        <span
          v-else-if="option && dfs_perimeters"
          class="option__title"
          :style="option.depth ? { 'margin-left': option.depth * 15 + 'px' } : null"
        >
          <i
            v-if="(option.tag && option.is_root) || option.id === 0"
            class="far fa-sitemap mr-2"
            :style="option.tag !== $store.getters.customer ? 'color:#0A4650;' : ''"
          />
          <i
            v-else-if="option.tag && !option.is_root"
            class="far fa-cubes mr-2"
          />
          <i
            v-else-if="(!option.tag && option.type === 'APPLICATION') || option.perimeter_type === 'APPLICATION'"
            class="fab fa-angular mr-2 fa-lg"
          />
          <i
            v-else-if="(!option.tag && option.type === 'PROVIDER') || option.perimeter_type === 'PROVIDER'"
            class="far fa-people-arrows mr-2"
          />
          <i
            v-else
            class="far fa-cube mr-2"
          />
          {{ option.name }}
        </span>
        <template v-else-if="option">
          <span>{{ option[options_label] }}</span>
          <span
            v-if="show_times"
            class="ms-times pull-right"
            ><translate>Remove</translate></span
          >
        </template>
      </template>

      <slot
        v-for="slot in Object.keys($slots)"
        :slot="slot"
        :name="slot"
      />
      <template
        v-for="slot in Object.keys($scopedSlots)"
        :slot="slot"
        slot-scope="scope"
      >
        <slot
          v-bind="scope"
          :name="slot"
        />
      </template>
    </multiselect>

    <div
      v-else-if="value && !show_input && !not_set"
      class="pt-2"
      :style="{ 'min-height': '40px' }"
    >
      <template v-if="multiple && value.length > 0">
        <span
          v-for="v in value"
          :key="v[trackby]"
        >
          <b-badge>{{ v[options_label] }}</b-badge>
        </span>
      </template>
      <template v-else>
        {{ value[options_label] }}
      </template>
    </div>
    <div v-else>
      <span class="text-muted">
        <i><translate>Not set</translate></i>
      </span>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { isNameFormatValid } from '@/helpers/StringFormat.helpers'

export default {
  name: 'form-field-select',
  components: {
    Multiselect,
  },
  props: {
    name: String,
    label: String,
    is_bold: {
      type: Boolean,
      default: true,
    },
    fg_classes: String,
    help: String,
    dfs_perimeters: {
      type: Boolean,
      default: false,
    },
    hide_selected: {
      type: Boolean,
      default: false,
    },
    grp_perimeters: {
      type: Boolean,
      default: false,
    },
    allow_empty: {
      type: Boolean,
      default: false,
    },
    show_input: {
      type: Boolean,
      default: false,
    },
    search_identifier: {
      type: Boolean,
      default: false,
    },
    max_height: {
      type: Number,
      default: 300,
    },
    is_loading: {
      type: Boolean,
      default: null,
    },
    options: Array,
    'group-values': String,
    'group-label': String,
    'tag-placeholder': String,
    'group-select': Boolean,
    'close-on-select': {
      type: Boolean,
      default: true,
    },
    'v-model': Array,
    'confirm-delete': Boolean,
    enable_select_all: Boolean,
    enable_select_none: Boolean,
    multiple: Boolean,
    trackby: String,
    placeholder: [Object, String],
    taggable: {
      type: Boolean,
      default: false,
    },
    options_label: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    remote_search: {
      type: Boolean,
      default: false,
    },
    error: {
      type: [String, Object],
      required: false,
    },
    api_endpoint_tag_post: {
      type: String,
      required: false,
    },
    tagPlaceholder: {
      type: String,
      required: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    over_block: {
      type: Boolean,
      default: false,
    },
    value: [Object, Array, String],
    /**
     * Overrides the v-validate error message
     */
    custom_error_message: {
      type: String,
    },
  },
  data: function () {
    return {
      touched: false,
      options_: [],
      tag_warning: false,
      option_title_fa_icons: {
        APPLICATION: 'fab fa-angular fa-lg',
        PROVIDER: 'far fa-people-arrows',
        INTERNAL: 'far fa-cube',
      },
    }
  },
  computed: {
    is_perimeter() {
      return this.name === 'perimeter' || this.name === 'measure_perimeter'
    },
    show_times: function () {
      if (!this.multiple) {
        return this.allow_empty
      } else {
        if (this.allow_empty) {
          return true
        } else {
          return this.value && this.value.length > 1
        }
      }
    },
    show_field: function () {
      if (this.disabled && (this.value === null || typeof this.value === 'undefined' || this.value.length === 0)) {
        return false
      }
      return true
    },
    label_: function () {
      if (this.disabled) {
        return this.label.replace('*', '')
      }
      return this.label
    },
    not_set: function () {
      return (
        (this.value && Object.keys(this.value).length === 0) ||
        this.value === null ||
        typeof this.value === 'undefined' ||
        this.value.length === 0
      )
    },
    invalidFeedbackErrorMessage() {
      return this.custom_error_message || this.error
    },
  },
  watch: {
    options: function (v) {
      if (v) {
        this.options_ = v
      }
    },
  },
  mounted: function () {
    const _this = this
    this.$root.$on('data:submit', () => {
      _this.touched = false
    })
    if (this.options) {
      this.options_ = this.options
    }
  },
  methods: {
    returnPlaceholder(holder) {
      return holder ?? this._('Select option')
    },
    returnTagPlaceholder(tag) {
      if (tag) {
        return tag
      } else {
        return this._('Press enter to create')
      }
    },
    addTag(newTag) {
      const obj = {}
      if (this.api_endpoint_tag_post) {
        if (!isNameFormatValid(newTag)) {
          this.tag_warning = true
          return false
        } else {
          this.tag_warning = false
        }
        if (this.$store.getters.lang === 'fr') {
          obj['name_fr'] = newTag
        } else {
          obj['name_en'] = newTag
        }
        this.$api.post(this.api_endpoint_tag_post, obj).then((res) => {
          this.$emit('tag', res.data.object)
        })
      } else {
        this.$emit('tag', newTag)
      }
    },
    findNameIdentifier(q) {
      if (this.remote_search) {
        this.$emit('search', q)
        return
      }
      if (!this.search_identifier) {
        return
      }
      const regex = new RegExp(q, 'i')
      if (q.length > 1) {
        this.options_ = this.options.filter((e) => regex.test(e.name + ' ' + e.identifier))
      } else {
        this.options_ = this.options
      }
      this.$log.debug(this.options_)
    },
    changed: function (value) {
      this.touched = true
      if (this.api_endpoint_tag_post && !isNameFormatValid(value)) {
        this.tag_warning = true
      } else {
        this.tag_warning = false
      }
      this.$emit('input', value)
      this.$emit('change', value)
    },
    selectNone: function () {
      const _this = this
      if (this.confirmDelete) {
        this.confirm(
          this._('Removing will delete every related data, are you sure?'),
          this._('Delete'),
          this._('Cancel')
        )
          .then(() => {
            _this.$emit('select_none')
          })
          .catch(() => {
            _this.$emit('cancel_select_none')
          })
      } else {
        _this.$emit('select_none')
      }
    },
    remove: function (value) {
      const _this = this
      if (this.confirmDelete) {
        this.confirm(
          this._('Removing will delete every related data, are you sure?'),
          this._('Delete'),
          this._('Cancel')
        )
          .then(() => {})
          .catch((err) => {
            _this.$emit('cancel_delete', value)
            _this.$log.debug(err)
          })
      }
    },
  },
}
</script>

<style scoped>
.noBackgroundSelectButton {
  color: #0a4650 !important;
  background-color: #ffffff !important;
  border: 1px #0a4650 solid !important;
}

.label-weight {
  font-weight: 400 !important;
}
</style>
