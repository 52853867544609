import VueI18n, { type DateTimeFormatOptions, type DateTimeFormats } from 'vue-i18n'
import { FALLBACK_LOCALE, LOCALE_TO_LANG } from '@/constants/language'
import Vue from 'vue'
import { getNavigatorLocale, localeToLang } from '@/helpers/Language.helpers'

const I18N_ERROR = 'I18n Error'

type File = Record<string, unknown>
type Source = Record<string, unknown>
type Sources = Record<string, Source>

const sources: Sources = {
  en: import.meta.glob(['@/**/locales/**/*.i18n.en.json', '@/contexts/**/primary/**/*.i18n.en.json'], {
    eager: true,
    import: 'default',
  }),
  fr: import.meta.glob(['@/**/locales/**/*.i18n.fr.json', '@/contexts/**/primary/**/*.i18n.fr.json'], {
    eager: true,
    import: 'default',
  }),
}

const messages = Object.keys(sources).reduce(
  (translations, sourceLocalKey: string) => ({
    ...translations,
    [sourceLocalKey]: Object.keys(sources[sourceLocalKey]).reduce((message, file: string) => {
      if (Object.prototype.toString.call(sources[sourceLocalKey][file]) !== '[object Object]') {
        throw new Error(`${I18N_ERROR} : "${file}" file must be an object`)
      }

      const current = sources[sourceLocalKey][file] as File

      Object.keys(current).forEach((key) => {
        if (Object.prototype.toString.call(current[key]) !== '[object Object]') {
          throw new Error(`${I18N_ERROR} : ${file} : "${key}" value must be an object`)
        }
      })

      return { ...((message as object) ?? {}), ...((current as object) ?? {}) }
    }, {}),
  }),
  {}
)

const short: DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
}

const dateTimeFormats: DateTimeFormats = {
  en: {
    short,
  },
  fr: {
    short,
  },
}

Vue.use(VueI18n)

const navigatorLocale = getNavigatorLocale().replace('-', '_')
const defaultLocale = localeToLang(navigatorLocale)

const options: VueI18n.I18nOptions = {
  locale: defaultLocale,
  fallbackLocale: LOCALE_TO_LANG[FALLBACK_LOCALE],
  messages,
  dateTimeFormats,
}

export const i18n = new VueI18n(options)

export const setI18nLocale = (locale: keyof typeof LOCALE_TO_LANG) => {
  i18n.locale = locale
}

// Current vue-i18n version v8 uses deprecated tc() in v10 for pluralization. We simplify the migration by allowing to pluralize using only t() with different signatures.
// i18n.t current interface returns VueI18n.TranslateResult, which is not a practical type. We cast it to string for usage.
export function t(key: VueI18n.Path, values?: VueI18n.Values): string
export function t(key: VueI18n.Path, choice?: VueI18n.Choice, values?: VueI18n.Values): string
export function t(...args: unknown[]): string {
  // @ts-expect-error : error in t typing
  return typeof args[1] === 'number' ? i18n.tc(...args) : i18n.t(...args).toString()
}

Vue.prototype.t = t
