<template>
  <b-dropdown
    ref="dropdown"
    class="tenacy-btn-xls"
    data-testid="dropdown-xls"
    :disabled="disabled_condition"
    right
    split
    split-class="xls-split-left"
    :text="''"
    variant="outline-primary"
    @click="openDropdown()"
  >
    <template #button-content>
      <i class="far fa-sort-alt img-xls" />
    </template>
    <slot />
  </b-dropdown>
</template>

<script>
export default {
  name: 'app-button-xls',
  props: {
    disabled_condition: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },
  methods: {
    openDropdown() {
      if (this.$refs.dropdown) {
        this.$log.debug('OPEN DROPDOWN')
        this.$refs.dropdown.show()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.tenacy-btn-xls {
  height: 40px;
  background-color: #ffffff !important;
  border-color: #0a4650 !important;
  border-radius: 4px !important;
}

.img-xls {
  height: 100%;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
</style>
