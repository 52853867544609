import store from '@/store'
import { hasFeatureFlagAccess } from '@/contexts/shared/feature-flags/FeatureFlags.helpers'
import { config } from '@/config.settings'
import router from '@/router/router'
import LoadingComponent from '@/components/unauth-pages/loading.vue'
import ErrorComponent from '@/components/unauth-pages/error-reload.vue'

export const _ = (s) => {
  return s
}

const adminComponentMapping = {
  home: () => import('@/components/pages/global-home.vue'),
  cockpit: () => import('@/components/pages/global-home-default.vue'),
  onboarding: () => import('@/components/pages/onboarding.vue'),
}

const contribComponentMapping = {
  home: () => import('@/components/pages/local-home.vue'),
  cockpit: () => import('@/components/pages/local-home-usr.vue'),
}

export const allRoles = ['global_pilote', 'global_auditor', 'local_pilote', 'local_auditor', 'contributor']

export const rootAdmin = ['root', 'support']

export const onlyRootAdmin = ['root']

export const staffPlusAndMore = ['root', 'support', 'staff+']

export const StaffAndMore = ['root', 'support', 'staff+', 'staff']

export const supportOnly = ['support']

export const piloteGlobalOnly = ['global_pilote']

export const piloteOnly = ['global_pilote', 'local_pilote']

export const piloteAndAuditor = ['global_pilote', 'global_auditor', 'local_pilote', 'local_auditor']

export const piloteAndContributor = ['global_pilote', 'local_pilote', 'contributor']

export const isGlobalPiloteOrAuditor = ['global_pilote', 'global_auditor']

export const requiresInternal = () => {
  return store.getters.isContributor
}

export const redirectHome = () => {
  return {
    name: store.getters.isPublic ? 'customer' : 'home',
  }
}

export const hasOption = (name) => {
  let has_option
  if (store.getters.context_type === 'ASSESSMENT') {
    return true
  }
  switch (name) {
    case 'projects':
      has_option = store.getters.isp_enable == true
      break
    case 'risk_analysis':
      has_option = store.getters.risk_analysis_enable == true
      break
    default:
      has_option = false

      break
  }
  return has_option
}

const featureFlagCheck = (route) => {
  if (route.meta && route.meta.requires_feature_flag) {
    return hasFeatureFlagAccess(store.getters.customer_features_flags, route.meta.requires_feature_flag)
  }
  return true
}

export const isAuthorized = (route) => {
  // if route is "test" you need to be in development mode
  if (route.path === '/ui-kit' && config.dev !== true) {
    return false
  }

  if (featureFlagCheck(route) === false) {
    return false
  }

  // if route doesn't need to be logged in, route is authorized
  if (route.requires_auth === false) {
    return true
  }

  // Check if route is a paid option

  if (route.meta.is_optional === true) {
    const has_option = route.meta.has_option()
    if (!has_option) {
      return false
    }
  }

  // Check user role
  const has_role = store.getters.hasNamedRole(route.meta.requires_role)
  if (!has_role) {
    return false
  }

  // check user feature access
  const has_feature_access = route.meta.feature_id ? store.getters.hasFeature(route.meta.feature_id) : true
  if (!has_feature_access) {
    return false
  }

  // check all tenant authorization
  const has_tenant = !route.meta.requires_tenant || !store.getters.isPublic
  const has_no_tenant = !route.meta.requires_no_tenant || store.getters.isPublic
  if (!has_tenant || !has_no_tenant) {
    return false
  }

  // check all instance type authorization
  const enable_fp_assessment = !(
    route.meta.hidden_for_fp_assessment === true &&
    store.getters.context_type === 'ASSESSMENT' &&
    !(store.getters.risk_analysis_enable && route.meta.for_fp_assessment_risk_analysis)
  )
  const enable_fe_student = !(route.meta.hidden_for_fe_student === true && store.getters.context_type === 'STUDENT')
  const enable_for_partner =
    !route.meta.for_partner || (route.meta.for_partner === true && store.getters.instance_type === 'partner')
  if (!enable_fp_assessment || !enable_for_partner || !enable_fe_student) {
    return false
  }

  // check specific route authorization
  const specific_route_enable =
    (route.name !== 'public_api' || store.getters.hidePublicApi === false) &&
    (route.name !== 'customer_connector' ||
      (store.getters.has_connector === true && store.getters.connector_allowed === true)) &&
    (route.name !== 'catalog_sync' || store.getters.instance_type === 'catalog') &&
    (route.name !== 'properties' || store.getters.instance_type === 'catalog') &&
    (route.name !== 'login-message' || store.getters.instance_type === 'catalog') &&
    (route.name !== 'business_impacts_scales' || store.getters.risk_analysis_enable || store.getters.isPublic)
  if (!specific_route_enable) {
    return false
  }

  // check provider attachment
  const provider = !route.meta.requires_internal || !store.getters.isLocalProvider
  if (!provider) {
    return false
  }
  return has_role && has_feature_access && has_tenant && has_no_tenant && specific_route_enable && provider
}

export const setComponentByRole = (componentName) => {
  let component
  if (store.getters.isGlobalPilote && store.getters.onboarding_enabled === true) {
    component = adminComponentMapping['onboarding']()
  } else if (store.getters.isPiloteOrAuditor) {
    component = adminComponentMapping[componentName]()
  } else if (store.getters.isContributor) {
    component = contribComponentMapping[componentName]()
  } else {
    return () => import('@/components/unauth-pages/error-404.vue')
  }
  return component
}

export const beforeRiskAnalysis = (_to, _from, next) => {
  void store.dispatch('updateTutoStepAnalysis').then(() => {
    next()
  })
}

export const setNavL2 = (to, _from, next) => {
  const route = router.getRoutes().find((route) => route.name === to.name)
  void store.dispatch('SET_NAV_L2', { parent_name: route.parent.name, child_name: to.name })
  next()
}

export const lazyLoadView = (AsyncView) => {
  const AsyncHandler = () => ({
    component: AsyncView,
    loading: LoadingComponent,
    delay: 200,
    error: ErrorComponent,
    timeout: 100000,
  })
  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      return h(AsyncHandler, data, children)
    },
  })
}
