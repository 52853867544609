<template>
  <div class="ml-0 d-inline-block">
    <template v-if="order == 'asc'">
      <slot name="vbt-sort-asc-icon" />
    </template>

    <template v-else-if="order === 'desc'">
      <slot name="vbt-sort-desc-icon" />
    </template>

    <template v-else>
      <slot name="vbt-no-sort-icon" />
    </template>
  </div>
</template>

<script>
import findIndex from 'lodash/findIndex'

export default {
  name: 'SortIcon',
  props: {
    sort: {
      type: Array,
      default: function () {
        return []
      },
    },
    column: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data: function () {
    return {}
  },
  computed: {
    order() {
      const index = findIndex(this.sort, {
        vbt_col_id: this.column.vbt_col_id,
      })
      if (index == -1) {
        return null
      } else {
        return this.sort[index].order
      }
    },
  },
}
</script>
