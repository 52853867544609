import MainContent from '@/components/layout/main-content.vue'
import { _, isAuthorized, lazyLoadView, piloteAndAuditor, piloteOnly } from '@/router/routing'
import { passThroughComponent } from '@/plugins/pass-through-component'
import { recurringTasksRoutes } from '@/contexts/recurring-tasks/RecurringTasks.routes'
import { incidentsRoutes } from '@/contexts/incidents/Incidents.routes'
import { gapsRoutes } from '@/contexts/gaps/Gaps.routes'
import { exemptionsRoutes } from '@/contexts/exemptions/Exemptions.routes'
import { projectsSecurityRoutes } from '@/contexts/projects-security/ProjectsSecurity.routes'
import { actionPlansRoutes } from '@/contexts/action-plans/ActionPlans.routes'
import { campaignsRoutes } from '@/contexts/campaigns/Campaigns.routes'
import type { Route, RouteConfig } from 'vue-router'

export const operationRoutes: RouteConfig = {
  path: '/ops',
  name: 'compliance',
  component: MainContent,
  meta: {
    title: _('operations'),
    dummy: true,
    requires_auth: true,
    requires_tenant: true,
    requires_role: piloteAndAuditor,
    is_authorized: (route: Route) => isAuthorized(route),
  },
  children: [
    campaignsRoutes,
    actionPlansRoutes,
    recurringTasksRoutes,
    gapsRoutes,
    exemptionsRoutes,
    incidentsRoutes,
    projectsSecurityRoutes,
    {
      path: '/suppliers',
      component: {
        render: passThroughComponent,
      },
      meta: {
        title: _('Suppliers'),
        icon: 'people-arrows',
        alt_title: _('Supplier management'),
        requires_auth: true,
        requires_role: piloteOnly,
        is_authorized: (route: Route) => isAuthorized(route),
        requires_feature_flag: 'supplier_management',
      },

      children: [
        {
          path: '',
          name: 'suppliers',
          component: () => lazyLoadView(import('@/views/Suppliers/SuppliersListView.vue')),

          meta: {
            requires_auth: true,
            requires_role: piloteOnly,
            is_authorized: (route: Route) => isAuthorized(route),
            doc_id: 'suppliers',
          },
        },
        {
          path: 'results/:id',
          name: 'suppliers-results',
          component: () => lazyLoadView(import('@/views/Suppliers/SuppliersResultsView.vue')),

          meta: {
            title: null,
            requires_auth: true,
            requires_role: piloteOnly,
            is_authorized: (route: Route) => isAuthorized(route),
          },
        },
        {
          path: ':id',
          name: 'supplier-validated',
          component: () => lazyLoadView(import('@/views/Suppliers/SupplierValidatedView.vue')),

          meta: {
            title: null,
            requires_auth: true,
            requires_role: piloteOnly,
            is_authorized: (route: Route) => isAuthorized(route),
          },
        },
        {
          path: ':id/details',
          name: 'supplier-details',
          component: () => lazyLoadView(import('@/views/Suppliers/SupplierDetailsView.vue')),

          meta: {
            title: null,
            requires_auth: true,
            requires_role: piloteOnly,
            is_authorized: (route: Route) => isAuthorized(route),
          },
        },
      ],
    },
  ],
}
