import { _, isAuthorized, lazyLoadView, piloteAndAuditor, setNavL2 } from '@/router/routing'
import type { Route, RouteConfig } from 'vue-router'

export const indicatorsRoutes: RouteConfig = {
  path: 'indicators',
  name: 'indicator',
  component: () => lazyLoadView(import('@/contexts/indicators/IndicatorsView.vue')),
  beforeEnter: setNavL2,
  meta: {
    page_title: 'Indicators',
    i18nTitle: 'dashboards.menu.indicators',
    icon: 'chart-line',
    is_authorized: (route: Route) => isAuthorized(route),
    requires_auth: true,
    requires_tenant: true,
    requires_role: piloteAndAuditor,
    doc_id: 'indicator',
    feature_id: 'DASHBOARDS',
  },
}

export const indicatorsAdminRoutes: RouteConfig = {
  path: 'indicators',
  name: 'indicators',
  component: () => lazyLoadView(import('@/contexts/indicators/IndicatorsCatalogView.vue')),
  meta: {
    title: _('Indicators'),
    is_authorized: (route: Route) => isAuthorized(route),
    requires_auth: true,
    requires_tenant: false,
    requires_role: piloteAndAuditor,
    doc_id: 'catalog',
    feature_id: 'CATALOG',
  },
}
