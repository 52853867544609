import type { Route, RouteConfig } from 'vue-router'
import { _, isAuthorized, lazyLoadView, piloteAndAuditor } from '@/router/routing'
import MainContent from '@/components/layout/main-content.vue'
import { risksRoutes } from '@/contexts/risks/Risks.routes'
import { securityBaseV2Routes } from '@/contexts/security-base-v2/SecurityBaseV2.routes'
import { policiesRoutes } from '@/contexts/policies/Policies.routes'

export const strategyRoutes: RouteConfig = {
  path: '/org',
  name: 'organization',
  component: MainContent,
  meta: {
    title: _('Strategy'),
    dummy: true,
    requires_auth: true,
    requires_tenant: true,
    requires_role: piloteAndAuditor,
    is_authorized: (route: Route) => isAuthorized(route),
  },
  children: [
    ...securityBaseV2Routes,
    {
      path: '/modeling',
      name: 'perimeters',
      component: () => lazyLoadView(import('@/components/pages/tags.vue')),
      meta: {
        title: _('Organization'),
        icon: 'sitemap',
        is_authorized: (route: Route) => isAuthorized(route),
        requires_auth: true,
        requires_tenant: true,
        requires_role: piloteAndAuditor,
        doc_id: 'organization',
        feature_id: 'SECURITY_BASE',
      },
      children: [
        {
          path: 'filtered/list',
          name: 'filtered_perimeter',
          component: () => lazyLoadView(import('@/components/forms/filter-perimeters-list.vue')),
          meta: {
            title: _('Advanced Filter'),
            icon: 'stream',
            is_authorized: (route: Route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: piloteAndAuditor,
            doc_id: 'filtered_perimeter',
          },
        },
      ],
    },
    policiesRoutes,
    risksRoutes,
  ],
}
