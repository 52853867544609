import MainContent from '@/components/layout/main-content.vue'
import {
  _,
  allRoles,
  isAuthorized,
  lazyLoadView,
  piloteAndAuditor,
  piloteAndContributor,
  piloteOnly,
  requiresInternal,
  setComponentByRole,
  setNavL2,
} from '@/router/routing'
import { incidentsContributionRoutes } from '@/contexts/incidents/Incidents.routes'
import { recurringTasksContributionRoutes } from '@/contexts/recurring-tasks/RecurringTasks.routes'
import { exemptionsContributionRoutes } from '@/contexts/exemptions/Exemptions.routes'
import { projectsSecurityContributionRoutes } from '@/contexts/projects-security/ProjectsSecurity.routes'
import { actionPlansContributionRoutes } from '@/contexts/action-plans/ActionPlans.routes'
import { risksContributionRoutes } from '@/contexts/risks/Risks.routes'
import { uiKitRoutes } from '@/router/routes/nav-routes/UiKit.routes'
import type { Route, RouteConfig } from 'vue-router'

export const myRoutes: RouteConfig = {
  path: '/my',
  name: 'myspace',
  component: MainContent,
  meta: {
    title: _('my space'),
    dummy: true,
    requires_auth: true,
    requires_tenant: true,
    requires_role: allRoles,
    is_authorized: (route: Route) => isAuthorized(route),
  },
  children: [
    uiKitRoutes,
    {
      path: '/home',
      name: 'home',
      component: () => lazyLoadView(setComponentByRole('home')),
      meta: {
        title: _('Cockpit'),
        icon: 'home',
        onboarding_page: true,
        is_authorized: (route: Route) => isAuthorized(route),
        requires_auth: true,
        requires_tenant: true,
        requires_role: allRoles,
        doc_id: 'home',
      },
      children: [
        {
          path: 'name',
          name: 'onb-customer-name',
          component: () => lazyLoadView(import('@/components/pages/onb-customer-name.vue')),
          meta: {
            title: _("1.1 - Organisation's name"),
            icon: 'signal',
            do_not_show: true,
            is_authorized: (route: Route) => isAuthorized(route),
            requires_role: piloteOnly,
            requires_auth: true,
            requires_tenant: true,
            doc_id: 'ob_access_control',
          },
        },
        {
          path: 'access_control',
          name: 'onb-access-control',
          component: () => lazyLoadView(import('@/components/pages/onb-access-control.vue')),
          meta: {
            title: _('Access control'),
            icon: 'signal',
            do_not_show: true,
            is_authorized: (route: Route) => isAuthorized(route),
            requires_role: piloteOnly,
            requires_auth: true,
            requires_tenant: true,
            doc_id: 'ob_access_control',
          },
        },
        {
          path: 'policy-industry',
          name: 'onb-policy-industry',
          component: () => lazyLoadView(import('@/components/pages/onb-policy-industry.vue')),
          meta: {
            title: _('Onboarding - Proposed policies based on industry and activity sector'),
            icon: 'signal',
            do_not_show: true,
            is_authorized: (route: Route) => isAuthorized(route),
            requires_role: piloteOnly,
            requires_auth: true,
            requires_tenant: true,
            doc_id: 'ob_access_control',
          },
        },
        {
          path: 'policy-regulations',
          name: 'onb-policy-regulations',
          component: () => lazyLoadView(import('@/components/pages/onb-policy-regulation.vue')),
          meta: {
            title: _('Onboarding - Regulatory and legal requirements'),
            icon: 'signal',
            do_not_show: true,
            is_authorized: (route: Route) => isAuthorized(route),
            requires_role: piloteOnly,
            requires_auth: true,
            requires_tenant: true,
            doc_id: 'ob_access_control',
          },
        },
        {
          path: 'policy-certifications',
          name: 'onb-policy-certifications',
          component: () => lazyLoadView(import('@/components/pages/onb-policy-certifications.vue')),
          meta: {
            title: _('Onboarding - Certifications'),
            icon: 'signal',
            do_not_show: true,
            is_authorized: (route: Route) => isAuthorized(route),
            requires_role: piloteOnly,
            requires_auth: true,
            requires_tenant: true,
            doc_id: 'ob_access_control',
          },
        },
        {
          path: 'perimeter-mapping',
          name: 'onb-perimeter-mapping',
          component: () => lazyLoadView(import('@/components/pages/onb-perimeter-mapping.vue')),
          meta: {
            title: _('Onboarding - Perimeter mapping'),
            icon: 'signal',
            do_not_show: true,
            is_authorized: (route: Route) => isAuthorized(route),
            requires_role: piloteOnly,
            requires_auth: true,
            requires_tenant: true,
            doc_id: 'ob_access_control',
          },
        },
        {
          path: 'action-plans',
          name: 'onb-action-plans',
          component: () => lazyLoadView(import('@/components/pages/onb-action-plans.vue')),
          meta: {
            title: _('Onboarding - Action plans'),
            icon: 'signal',
            do_not_show: true,
            is_authorized: (route: Route) => isAuthorized(route),
            requires_role: piloteOnly,
            requires_auth: true,
            requires_tenant: true,
            doc_id: 'ob_action_plans',
          },
        },
        {
          path: ':id/action-plans-mapping',
          name: 'onb-action-plans-mapping',
          component: () => lazyLoadView(import('@/contexts/action-plans/ActionPlanMappingView.vue')),
          meta: {
            do_not_show: true,
            title: null,
            is_authorized: (route: Route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: piloteOnly,
            doc_id: 'action-registers-mapping',
          },
        },
        {
          path: 'policy-import-mapping',
          name: 'onb-policy-import-mapping',
          component: () => lazyLoadView(import('@/components/pages/onb-policy-import-mapping.vue')),
          meta: {
            title: _('Onboarding - Import existing policies'),
            icon: 'signal',
            do_not_show: true,
            is_authorized: (route: Route) => isAuthorized(route),
            requires_role: piloteOnly,
            requires_auth: true,
            requires_tenant: true,
            doc_id: 'ob_access_control',
          },
        },
        {
          path: 'policy-import-mapping/:id',
          name: 'onb_policy_version_catalog',
          component: () => lazyLoadView(import('@/contexts/policies/PolicyVersionView.vue')),
          meta: {
            title: null,
            requires_auth: true,
            do_not_show: true,
            requires_tenant: false,
            sidebar: false,
            is_authorized: (route: Route) => isAuthorized(route),
            requires_role: piloteAndAuditor,
            doc_id: 'policy_details',
          },
        },
        {
          path: 'security-objectives',
          name: 'onb-security-objectives',
          component: () => lazyLoadView(import('@/components/pages/onb-security-objectives.vue')),
          meta: {
            title: _('Onboarding - Security objectives'),
            icon: 'signal',
            do_not_show: true,
            is_authorized: (route: Route) => isAuthorized(route),
            requires_role: piloteOnly,
            requires_auth: true,
            requires_tenant: true,
            doc_id: 'ob_access_control',
          },
        },
        {
          path: 'connectors',
          name: 'onb-connectors',
          component: () => lazyLoadView(import('@/components/pages/onb-connectors.vue')),
          meta: {
            title: _('Onboarding - Connectors'),
            icon: 'signal',
            do_not_show: true,
            is_authorized: (route: Route) => isAuthorized(route),
            requires_role: piloteOnly,
            requires_auth: true,
            requires_tenant: true,
            doc_id: 'ob_connectors',
          },
        },
        {
          path: 'security-bases',
          name: 'onb-security-bases',
          component: () => lazyLoadView(import('@/components/pages/onb-security-bases.vue')),
          meta: {
            title: _('Onboarding - Security bases'),
            icon: 'signal',
            do_not_show: true,
            is_authorized: (route: Route) => isAuthorized(route),
            requires_role: piloteOnly,
            requires_auth: true,
            requires_tenant: true,
            doc_id: 'ob_security_bases',
          },
        },
      ],
    },
    {
      path: '/cockpit',
      name: 'cockpit',
      component: () => lazyLoadView(setComponentByRole('cockpit')),
      meta: {
        sidebar: false,
        title: _('Cockpit'),
        icon: 'home-alt',
        is_authorized: (route: Route) => isAuthorized(route),
        requires_auth: true,
        requires_tenant: true,
        requires_role: allRoles,
        doc_id: 'home',
      },
    },
    {
      path: '/contributions',
      name: 'contributions',
      component: MainContent,
      meta: {
        title: _('Contributions'),
        icon: 'comment-alt-plus',
        dummy: true,
        multi: true,
        requires_auth: true,
        requires_tenant: true,
        requires_role: piloteAndContributor,
        is_authorized: (route: Route) => isAuthorized(route),
      },
      children: [
        {
          path: 'evaluations',
          name: 'evaluation',
          component: () => lazyLoadView(import('@/components/pages/campaigns-local.vue')),
          beforeEnter: setNavL2,
          meta: {
            title: _('Evaluations'),
            icon: 'check-square',
            is_authorized: (route: Route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'my_evaluations',
            nb_contribution: 'nb_evaluations',
          },
          children: [
            {
              path: 'eval/:campaign_id/:perimeter_id?/:control_id?/:questionnaire_id?',
              name: 'campaign_eval',
              component: () => lazyLoadView(import('@/components/pages/campaign-eval.vue')),
              meta: {
                title: null,
                is_authorized: (route: Route) => isAuthorized(route),
                requires_auth: true,
                requires_tenant: true,
                requires_role: allRoles,
                doc_id: 'campaign_eval',
              },
            },
            {
              path: 'results/:campaign_id/:perimeter_id?',
              name: 'campaign_details',
              component: () => lazyLoadView(import('@/components/pages/campaign-details.vue')),
              meta: {
                title: null,
                is_authorized: (route: Route) => isAuthorized(route),
                requires_auth: true,
                requires_tenant: true,
                requires_role: allRoles,
                doc_id: 'campaign_details',
              },
            },
          ],
        },
        actionPlansContributionRoutes,
        recurringTasksContributionRoutes,
        {
          path: 'metrics',
          name: 'gmetrics_entry',
          component: () => lazyLoadView(import('@/contexts/metrics/MetricEntryView.vue')),
          beforeEnter: setNavL2,
          meta: {
            title: _('Metrics'),
            icon: 'chart-scatter',
            is_authorized: (route: Route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            requires_internal: () => requiresInternal(),
            doc_id: 'my_metrics',
            nb_contribution: 'nb_metrics',
          },
        },
        exemptionsContributionRoutes,
        risksContributionRoutes,
        incidentsContributionRoutes,
        projectsSecurityContributionRoutes,
      ],
    },
  ],
}
