import MainContent from '@/components/layout/main-content.vue'
import { isAuthorized, lazyLoadView, piloteAndAuditor, setNavL2 } from '@/router/routing'
import type { Route, RouteConfig } from 'vue-router'

import { indicatorsRoutes } from '@/contexts/indicators/Indicators.routes'

export const dashboardsRoutes: RouteConfig = {
  path: '/reporting',
  name: 'reporting',
  component: MainContent,
  meta: {
    i18nTitle: 'dashboards.menu.title',
    icon: 'tachometer-fast',
    multi: true,
    dummy: true,
    requires_auth: true,
    requires_tenant: true,
    requires_role: piloteAndAuditor,
    is_authorized: (route: Route) => isAuthorized(route),
    doc_id: 'dashboards',
    feature_id: 'DASHBOARDS',
  },
  children: [
    {
      path: 'dashboards',
      name: 'dashboard',
      component: () => lazyLoadView(import('@/contexts/dashboards/DashboardsView.vue')),
      beforeEnter: setNavL2,
      meta: {
        i18nTitle: 'dashboards.menu.title',
        icon: 'tachometer-fast',
        is_authorized: (route: Route) => isAuthorized(route),
        requires_auth: true,
        requires_tenant: true,
        hide_in_breadcrumb: true,
        requires_role: piloteAndAuditor,
        doc_id: 'dashboards',
        feature_id: 'DASHBOARDS',
      },
      children: [
        {
          path: ':id/builder',
          name: 'dashboard_builder',
          component: () => lazyLoadView(import('@/contexts/dashboards/DashboardBuilderView.vue')),
          meta: {
            title: null,
            is_authorized: (route: Route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: piloteAndAuditor,
            doc_id: 'dashboard_builder',
            feature_id: 'DASHBOARDS',
          },
        },
        {
          path: ':id/viewer',
          name: 'dashboard_viewer',
          component: () => lazyLoadView(import('@/contexts/dashboards/DashboardView.vue')),
          meta: {
            title: null,
            is_authorized: (route: Route) => isAuthorized(route),
            requires_tenant: true,
            requires_auth: true,
            requires_role: piloteAndAuditor,
            doc_id: 'dashboard_viewer',
            feature_id: 'DASHBOARDS',
          },
        },
      ],
    },
    {
      path: 'metrics',
      name: 'metric',
      component: () => lazyLoadView(import('@/components/pages/metrics.vue')),
      beforeEnter: setNavL2,
      meta: {
        i18nTitle: 'dashboards.menu.metrics',
        icon: 'chart-scatter',
        is_authorized: (route: Route) => isAuthorized(route),
        requires_auth: true,
        requires_tenant: true,
        requires_role: piloteAndAuditor,
        doc_id: 'metric',
        feature_id: 'DASHBOARDS',
      },
    },
    indicatorsRoutes,
  ],
}
