// TODO DELETE : This is just for testing score engine policy coverage in real environment
// (SEE https://excube.atlassian.net/browse/ACISO-10296)

import type { Route, RouteConfig } from 'vue-router'
import { isAuthorized, lazyLoadView, piloteAndAuditor } from '@/router/routing'

import MainApp from '@/components/main-app.vue'

export const policyScoreCoverageRoutes: RouteConfig[] = [
  {
    path: '/score-engine',
    component: MainApp,
    meta: {
      title: 'Score engine',
      icon: 'tachometer-fast',
      requires_auth: true,
      requires_tenant: true,
      requires_role: piloteAndAuditor,
      is_authorized: (route: Route) => isAuthorized(route),
    },
    children: [
      {
        path: 'policy-score-coverage',
        name: 'policy-score-coverage',
        component: () => lazyLoadView(import('@/contexts/policies/PolicyScoreCoverageView.vue')),
        meta: {
          title: 'Policy score coverage',
          is_authorized: (route: Route) => isAuthorized(route),
          requires_auth: true,
          requires_tenant: true,
          requires_role: piloteAndAuditor,
        },
      },
    ],
  },
]
