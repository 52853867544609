<template>
  <b-button
    v-b-tooltip
    :active="active"
    class="app-btn"
    :class="classes"
    :disabled="disabled_condition"
    :title="title"
    :variant="currentVariant"
    @click="emitClick"
  >
    <i
      v-if="custom_icon.length > 0"
      v-b-tooltip
      class="tenacy-btn-icon"
      :class="fa_type + ' fa-' + custom_icon + ' fa-fw' + (custom_icon_alone ? ' alone' : '')"
      :title="tooltip"
    />
    <span :class="is_outlined ? '' : disabled_condition ? 'disabled-label' : 'tenacy-btn-label'">
      <slot />
    </span>
    <i
      v-if="custom_icon_after.length > 0"
      v-b-tooltip
      class="tenacy-btn-icon"
      :class="fa_type + ' fa-' + custom_icon_after + ' fa-fw'"
      :title="tooltip"
    />
  </b-button>
</template>
<script>
export default {
  name: 'app-button',

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    custom_icon: {
      type: String,
      default: 'plus-circle',
    },
    custom_icon_alone: {
      type: Boolean,
      default: false,
    },
    custom_icon_after: {
      type: String,
      default: '',
    },
    fa_type: {
      type: String,
      default: 'far',
    },
    tooltip: String,
    title: {
      type: String,
      default: '',
    },
    disabled_condition: {
      type: Boolean,
      default: false,
    },
    is_outlined: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },
  computed: {
    classes() {
      const outlined_class = this.disabled_condition ? 'app-btn-disabled' : this.is_outlined ? '' : 'tenacy-btn ripple'
      const active_class = this.active ? 'active' : ''
      return outlined_class + ' ' + active_class
    },
    currentVariant() {
      if (this.is_outlined) {
        return 'outline-primary'
      } else if (this.disabled_condition) {
        return 'light-grey'
      }
      return 'primary'
    },
  },
  methods: {
    emitClick(ev) {
      if (!this.disabled) {
        this.$emit('click', ev)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.app-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tenacy-btn {
  height: 40px;
  background-color: #0a4650;
  padding: 5px 16px;
}

.tenacy-btn:hover {
  border: 1px solid #108181;
}

.tenacy-btn-icon {
  margin-right: 4px;
  vertical-align: middle;
}

.tenacy-btn-icon.alone {
  margin-right: 0px;
  vertical-align: middle;
}

.tenacy-btn-label {
  color: #ffffff;
  font-weight: 600;
  align-self: center;
}
.ripple {
  background-position: center;
  transition: background 0.8s;
}
.ripple:hover {
  background: #108181 radial-gradient(circle, transparent 1%, #108181 1%) center/15000%;
}
.ripple:active {
  background-color: #108181;
  background-size: 100%;
  transition: background 0s;
}
.button-green {
  border: 1px solid #28a745 !important;
  color: #ffffff;
  font-weight: bolder !important;
}

.button-red {
  border: 1px solid #e31919 !important;
  color: #ffffff;
  font-weight: bolder !important;
}

.button-gold {
  border: 1px solid #f2b847 !important;
  color: #ffffff;
  font-weight: bolder !important;
}
.button-gold:hover {
  border: 1px solid #28a745 !important;
  background-color: #28a745 !important;
}
.app-btn-disabled {
  background-color: #dfdfdf !important;
  border: 1px solid #dfdfdf !important;
  color: #888888 !important;
}
.disabled-label {
  color: #888888 !important;
  font-weight: 600;
}
</style>
