import Intercom from '@intercom/messenger-js-sdk'
import { useStore } from '@/store.js'
import { config } from '@/config.settings.js'

const store = useStore()

const getInstanceServerName = (instance) => {
  const mappings = {
    'Serveur dédié Monaco': (inst) => inst.includes('mnc'),
    'Serveur mutualisé SNC': (inst) => inst === 'fb-prod-snc',
    'Serveur dédié SNC': (inst) => inst.includes('snc') && inst !== 'fb-prod-snc',
    'Serveur mutualisé': (inst) => inst === 'prod',
    'Serveur dédié': (inst) => inst.startsWith('fb-'),
  }

  for (const [serverName, condition] of Object.entries(mappings)) {
    if (condition(instance)) {
      return serverName
    }
  }

  return 'Serveur mutualisé'
}

export const init = async (hash) => {
  const intercomAppId = config.intercom_app_id
  Intercom({
    app_id: intercomAppId,
    region: 'eu',
    user_id: store.getters.email,
    name: store.getters.name,
    email: store.getters.email,
    created_at: new Date(),
    user_hash: hash,
    Role: store.getters.instance_role,
    Tenant_name: store.getters.customer,
    Rattachement: store.getters.user_tag ? store.getters.user_tag : store.getters.user_perimeter,
    Authentification: store.getters.has_saml ? 'SAML' : 'PASSWORD',
    Instance_server_name_CSM: getInstanceServerName(store.getters.instance),
    Instance_server_type_DT: store.getters.instance,
    Url_server: window.location.origin,
  })
}

export default { init }
