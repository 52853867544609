import { _, lazyLoadView } from '@/router/routing'
import type { RouteConfig } from 'vue-router'

export const standaloneRoutes: RouteConfig[] = [
  {
    path: '/supplier-evaluation',
    name: 'supplier_evaluation',
    component: () => lazyLoadView(import('@/views/Suppliers/SuppliersEvaluationView.vue')),
    meta: {
      title: _('Suppliers evaluation'),
      icon: 'users',
      standalone_auth: true,
    },
  },
]
