import axios from 'axios' // great ajax library.
import { Validator } from 'vee-validate'

const axios_http = axios
axios_http.defaults.withCredentials = true

const isUnique = (value) => {
  return axios_http.post('/api/validate/email', { email: value }).then((response) => {
    return {
      valid: response.data.ok,
      data: {
        message: response.data.message,
      },
    }
  })
}

// The messages getter may also accept a third parameter that includes the data we returned earlier.
Validator.extend('unique-email', {
  validate: isUnique,
  getMessage: (_field, _params, data) => {
    return data.message
  },
})

const isLengthBetween = (value, { min, max } = {}) => {
  if (!value || value.length < min || (max && value.length > max)) {
    return {
      valid: false,
      data: { message: `You must select at least ${min} elements` },
    }
  } else {
    return {
      valid: true,
      data: {},
    }
  }
}

// The first param is called 'min', and the second is called 'max'.
const paramNames = ['min', 'max']

Validator.extend(
  'list-length',
  {
    validate: isLengthBetween,
    getMessage: (_field, _params, data) => {
      return data.message
    },
  },
  {
    paramNames,
  }
)

const isBigger = (value, [otherValue]) => {
  if (!value || !otherValue) {
    return {
      valid: true,
      data: {},
    }
  }
  if (+value >= +otherValue) {
    return {
      valid: true,
      data: {},
    }
  } else {
    return {
      valid: false,
      data: { message: `Value must be greater than ${otherValue}` },
    }
  }
}

Validator.extend(
  'isBigger',
  {
    validate: isBigger,
    getMessage: function (_field, _args, data) {
      return data.message
    },
  },
  {
    hasTarget: true,
  }
)
