<template>
  <router-view />
</template>

<style lang="scss">
$pretty--color-default: #bdc3c7;
$pretty--color-primary: #0a4650;
$pretty--color-info: #35abb0;
$pretty--color-success: #5cb85c;
$pretty--color-warning: #f0ad4e;
$pretty--color-danger: #bd582c;
$pretty--color-dark: #5a656b;

@import 'pretty-checkbox/src/pretty-checkbox.scss';
@import '../vendors/bootstrap-datetimepicker/bootstrap-datetimepicker.css';
@import 'vue-loading-overlay/dist/vue-loading.css';
@import 'vue-multiselect/dist/vue-multiselect.min.css';
@import 'vue2-dropzone/dist/vue2Dropzone.min.css';
@import 'dhx-diagram-package/codebase/diagram.css';
@import './scss/AdminLTE_excube';
@import './scss/excube';
@import 'overlayscrollbars/css/OverlayScrollbars.css';
@import '../vendors/tui-editor/tui-editor.css';
@import '../vendors/tui-editor/tui-editor-contents.css';
@import '@toast-ui/editor/dist/toastui-editor.css';
</style>

<script>
import axios from 'axios'

export default {
  name: 'Home',

  created: function () {
    this.$store.commit('set_current_env')
    axios.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch('logout', this)
        }
        throw err
      })
    })
  },
  beforeMount() {
    const { BASE_URL } = import.meta.env
    this.loadScript(`${BASE_URL}static/plugins/adminlte/adminlte.js`)
    this.loadScript(`${BASE_URL}static/plugins/jquery-ui/jquery-ui.min.js`)
    this.loadScript(`${BASE_URL}static/plugins/evol-colorpicker/evol-colorpicker.min.js`)
  },
  methods: {
    loadScript: function (url) {
      const plugin = document.createElement('script')
      plugin.setAttribute('src', url)
      plugin.async = false
      document.head.appendChild(plugin)
    },
  },
}
</script>
