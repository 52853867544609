import { _, allRoles, isAuthorized, lazyLoadView, piloteAndAuditor } from '@/router/routing'
import type { Route, RouteConfig } from 'vue-router'

export const campaignsRoutes: RouteConfig = {
  path: '/campaigns',
  name: 'campaign',
  component: () => lazyLoadView(import('@/contexts/campaigns/CampaignsView.vue')),
  meta: {
    title: _('Evaluations'),
    icon: 'check-square',
    is_authorized: (route: Route) => isAuthorized(route),
    requires_auth: true,
    requires_tenant: true,
    requires_role: piloteAndAuditor,
    doc_id: 'campaign',
    feature_id: 'CAMPAIGNS',
  },
  children: [
    {
      path: 'results/:campaign_id/:perimeter_id?',
      name: 'gcampaign_details',
      component: () => lazyLoadView(import('@/components/pages/campaign-details.vue')),
      meta: {
        title: null,
        is_authorized: (route: Route) => isAuthorized(route),
        requires_auth: true,
        requires_tenant: true,
        requires_role: piloteAndAuditor,
        doc_id: 'campaign_details',
      },
      children: [
        {
          path: 'eval-details',
          name: 'campaign_eval_details',
          component: () => lazyLoadView(import('@/components/pages/campaign-eval.vue')),
          meta: {
            title: null,
            is_authorized: (route: Route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            challenge_mode: true,
            doc_id: 'campaign_eval_details',
          },
        },
      ],
    },
  ],
}
