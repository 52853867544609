<template>
  <span>
    <b-modal
      id="isp_project_disabled"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      size="xl"
      :title="_('Activate an optional add-on: Projects security')"
      title-class="upsell-risk"
    >
      <b-row>
        <b-col cols="8">
          <b-container class="pr-4 pl-0">
            <p class="mb-4 text-justify">
              {{ header_licence_msg }}
            </p>
            <p class="mb-4 text-justify">
              {{ body_licence_msg }}
            </p>
            <p
              v-if="$store.getters.instance_type !== 'partner'"
              class="mb-4 text-justify"
            >
              {{ try_message }}
            </p>

            <b-row class="justify-content-center pl-1">
              <img
                alt="Upsell isp fr"
                class="card-img mt-3 upsell-img"
                :src="`${publicPath}static/img/upsell/upsell_isp_fr.png`"
            /></b-row>
          </b-container>
        </b-col>
        <b-col cols="4">
          <b-container class="right-section card">
            <b-row class="bg-tertiary header p-3">
              <h4 class="m-auto">
                <translate>Project security</translate>
              </h4>
            </b-row>
            <b-row class="p-4">
              <span class="title">
                <translate> Get a head start, gear up and take advantage of powerful benefits!</translate>
              </span>
            </b-row>
            <b-container class="px-4">
              <b-row class="mb-4 align-items-center">
                <b-col><i class="fas fa-check fa-2x text-tertiary" /></b-col>
                <b-col cols="10">
                  <span class="bullet-points">
                    <translate>Time saved through automated workflow</translate>
                  </span>
                </b-col>
              </b-row>
              <b-row class="mb-4 align-items-center">
                <b-col><i class="fas fa-check fa-2x text-tertiary" /></b-col>
                <b-col cols="10">
                  <span class="bullet-points">
                    <translate> Perfect visibility on all projects at the same time</translate>
                  </span>
                </b-col>
              </b-row>
              <b-row class="mb-4 align-items-center">
                <b-col><i class="fas fa-check fa-2x text-tertiary" /></b-col>
                <b-col cols="10">
                  <span class="bullet-points">
                    <translate>Centralized monitoring</translate>
                  </span>
                </b-col>
              </b-row>
              <b-row class="mb-4 align-items-center">
                <b-col><i class="fas fa-check fa-2x text-tertiary" /></b-col>
                <b-col cols="10">
                  <span class="bullet-points">
                    <translate>Increased awareness of security issues</translate>
                  </span>
                </b-col>
              </b-row>

              <span v-if="!trial_ended">
                <b-row
                  v-if="$store.getters.instance_type !== 'partner'"
                  class="mb-4 calender align-items-center"
                >
                  <b-col><i class="far fa-calendar-check fa-2x" /></b-col>
                  <b-col cols="10">
                    <span> <translate>Begin today and conclude on</translate> {{ trialEndDate(15) }} </span>
                  </b-col>
                </b-row>
                <span v-if="$store.getters.isGlobalPilote && $store.getters.instance_type !== 'partner'">
                  <b-button
                    class="w-100 upsell-risk-btn"
                    data-testid="upsell-risk-btn"
                    variant="tertiary"
                    @click="handleStart"
                  >
                    {{ _('Start a 15-day free trial') }}
                  </b-button>
                  <b-button
                    class="w-100 text-bold mt-2 upsell-risk-btn"
                    variant="outline-tertiary"
                    @click="contactCSM"
                  >
                    {{ _('Contact CSM') }}
                  </b-button>
                </span>
                <span v-else>
                  <b-button
                    class="w-100 upsell-risk-btn mt-4 trial-disabled-btn"
                    disabled
                  >
                    <translate key="upsell_admin">Contact your Administrator</translate>
                  </b-button>
                </span>
              </span>
              <span v-else>
                <b-row class="mb-4 calender align-items-center expired">
                  <b-col><i class="far fa-calendar-check fa-2x" /></b-col>
                  <b-col cols="10">
                    <span>
                      <translate>Trial expired</translate>
                    </span>
                  </b-col>
                </b-row>
                <span v-if="$store.getters.isGlobalPilote">
                  <b-button
                    class="w-100 upsell-risk-btn mt-4"
                    data-testid="upsell-risk-btn"
                    variant="tertiary"
                    @click="redirectionDismiss"
                  >
                    {{ _('Book an appointment with your CSM') }}
                  </b-button>
                </span>
                <span v-else>
                  <b-button
                    class="w-100 upsell-risk-btn mt-4 trial-disabled-btn"
                    disabled
                  >
                    {{ _('Contact your Administrator') }}
                  </b-button>
                </span>
              </span>
            </b-container>
          </b-container>
        </b-col>
      </b-row>
    </b-modal>
    <confirm-trial-modal
      name="confirm-trial-modal"
      option_type="ISP"
      @closeAnalysisModal="hideUpsellModal"
    />
  </span>
</template>

<script>
import ConfirmTrialModal from '@/components/ui/confirm-trial-modal.vue'

export default {
  name: 'project-security-upsell-modal',
  components: { ConfirmTrialModal },
  data() {
    return {
      header_licence_msg: this._(
        "Adopt the ISP add-on to ensure better consideration of security requirements throughout your organisation's project lifecycle."
      ),
      body_licence_msg: this._(
        "With ISP, you will enable project managers to independently align their projects with your organisation's security requirements. Make sure you don't add additional vulnerabilities while empowering project managers through a 'Security by design' approach."
      ),
      try_message: this._(
        'Try this add-on for free during 15 days or book an appointment with the support team to add it now to your Tenacy plan.'
      ),
      presales_manager: 'elise-chichereau',
      publicPath: import.meta.env.BASE_URL,
    }
  },
  computed: {
    trial_ended() {
      return !!(
        this.$store.getters.getTrialOption('ISP') && this.$store.getters.getTrialOption('ISP').status === 'DISMISSED'
      )
    },
  },
  methods: {
    contactCSM: function () {
      window.open(this.aciso_config.hubspot_url + this.presales_manager, '_blank')
      this.hideUpsellModal()
    },
    hideUpsellModal() {
      this.$bvModal.hide('isp_project_disabled')
    },
    trialEndDate(duration) {
      const today = new Date()
      today.setDate(today.getDate() + duration)
      const day = today.getDate()
      if (this.$store.getters.lang === 'fr') {
        return today.toLocaleString('fr-FR', { month: 'long', day: 'numeric' })
      } else {
        let suffix = 'th'
        if (day === 1 || day === 21 || day === 31) {
          suffix = 'st'
        } else if (day === 2 || day === 22) {
          suffix = 'nd'
        } else if (day === 3 || day === 23) {
          suffix = 'rd'
        }
        return today.toLocaleString('en-US', { month: 'long', day: 'numeric' }) + suffix
      }
    },
    redirectionDismiss: function () {
      window.open(this.aciso_config.hubspot_url + this.presales_manager, '_blank')
      this.hideUpsellModal()
    },
    handleStart() {
      this.$bvModal.show('confirm-trial-modal')
    },
  },
}
</script>

<style scoped>
.right-section {
  border-radius: 10px;
  height: 100%;
}

.header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-weight: bold;
  font-size: 16px;
}

.bullet-points {
  font-weight: 500;
}

.calender {
  color: #ababab;
}

.upsell-risk-btn {
  font-weight: 500;
  font-size: 18px;
}

.expired {
  color: #ff7a00;
}

.trial-disabled-btn {
  background-color: #ededed;
  color: #a6a6a6;
  cursor: alias !important;
  border: none !important;
}

.trial-disabled-btn:hover {
  border: none;
  background-color: #ededed !important;
  color: #a6a6a6 !important;
  cursor: alias !important;
}
</style>
