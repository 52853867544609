<template>
  <div>
    <div class="dropdown">
      <a
        :id="'multifilter_' + column.name"
        aria-expanded="false"
        aria-haspopup="true"
        class="btn btn-primary dropdown-toggle"
        data-toggle="dropdown"
        href="#"
        role="button"
      >
        {{ title }}
      </a>
      <div
        ref="vbt_dropdown_menu"
        aria-labelledby="dropdownMenuLink"
        class="dropdown-menu scrollable-menu"
      >
        <multi-select-all-item
          v-if="!isSingleMode && showSelectAllCheckbox"
          :is-all-options-selected="isAllOptionsSelected"
          :text="selectAllCheckboxText"
          @on-deselect-all-option="selected_option_indexes = []"
          @on-select-all-option="selectAllOptions"
        />
        <multi-select-item
          v-for="(option, key) in options"
          :key="key"
          :index="key"
          :is-single-mode="isSingleMode"
          :option="option"
          :selected-option-indexes="selected_option_indexes"
          @on-deselect-option="removeOption"
          @on-select-option="addOption"
        />
      </div>
    </div>
  </div>
</template>

<script>
import findIndex from 'lodash/findIndex'
import range from 'lodash/range'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import has from 'lodash/has'
import cloneDeep from 'lodash/cloneDeep'

import MultiSelectItem from './MultiSelectItem.vue'
import MultiSelectAllItem from './MultiSelectAllItem.vue'
import Vue from 'vue'

import { EventBus } from '../../event-bus.js'

export default {
  name: 'MultiSelect',
  components: {
    MultiSelectItem,
    MultiSelectAllItem,
  },
  props: {
    column: {
      type: Object,
      default: function () {
        return {}
      },
    },
    options: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data: function () {
    return {
      selected_option_indexes: [],
      canEmit: false,
    }
  },
  computed: {
    optionsCount() {
      return this.options.length
    },
    title() {
      const title = this.column.filter.placeholder ? this.column.filter.placeholder : 'Select options'

      if (this.selected_option_indexes.length === 0) {
        return title
      }

      if (this.selected_option_indexes.length > 0 && this.selected_option_indexes.length <= 1) {
        return this.options[this.selected_option_indexes[0]].name
      }

      return this.selected_option_indexes.length + ' selected'
    },

    mode() {
      let mode = 'single'
      if (has(this.column.filter, 'mode') && this.column.filter.mode == 'multi') {
        mode = 'multi'
      }
      return mode
    },

    isSingleMode() {
      return this.mode == 'single'
    },

    isAllOptionsSelected() {
      return this.options.length === this.selected_option_indexes.length
    },

    showSelectAllCheckbox() {
      if (!has(this.column.filter, 'select_all_checkbox')) {
        return true
      } else {
        return this.column.filter.select_all_checkbox.visibility
      }
    },

    selectAllCheckboxText() {
      if (!has(this.column.filter, 'select_all_checkbox')) {
        return 'Select All'
      } else {
        return has(this.column.filter.select_all_checkbox, 'text')
          ? this.column.filter.select_all_checkbox.text
          : 'Select All'
      }
    },
  },
  watch: {
    selected_option_indexes(newVal) {
      if (!this.canEmit) {
        return
      }

      const filtered_options = filter(this.options, (_option, index) => {
        return includes(newVal, index)
      })

      const payload = {}
      payload.column = cloneDeep(this.column)
      payload.selected_options = []

      filtered_options.forEach((option) => {
        payload.selected_options.push(option.value)
      })

      this.$emit('update-multi-select-filter', payload)
    },
    column(val) {
      const _this = this
      if (val.name === 'campaigns' || val.name === 'attn') {
        if (val.filter.init) {
          Vue.nextTick(function () {
            _this.addOption(val.filter.init.value)
          })
        }
      }
    },
  },
  mounted() {
    if (!has(this.column, 'filter.closeDropdownOnSelection') || !this.column.filter.closeDropdownOnSelection) {
      this.$refs.vbt_dropdown_menu.addEventListener(
        'click',
        function (e) {
          e.stopPropagation()
        },
        false
      )
    }

    EventBus.$on('reset-query', () => {
      this.selected_option_indexes = []
    })

    const lastIndex = this.optionsCount - 1

    if (has(this.column, 'filter.init.value')) {
      if (this.isSingleMode) {
        const index = this.column.filter.init.value
        if (index > lastIndex || index < 0) {
          return
        }
        this.addOption(index)
      } else {
        if (Array.isArray(this.column.filter.init.value)) {
          this.column.filter.init.value.forEach((index) => {
            if (index > lastIndex || index < 0) {
              return
            }
            this.addOption(index)
          })
        } else {
          this.$log.debug("Initial value for 'multi' mode should be an array")
        }
      }
    }

    this.$nextTick(() => {
      this.canEmit = true
    })
  },
  methods: {
    addOption(index) {
      if (this.isSingleMode) {
        this.resetSelectedOptions()
        this.selected_option_indexes.push(index)
      } else {
        const res = findIndex(this.selected_option_indexes, function (option_index) {
          return option_index == index
        })
        if (res == -1) {
          this.selected_option_indexes.push(index)
        }
      }
    },
    selectAllOptions() {
      this.resetSelectedOptions()
      this.selected_option_indexes = range(this.options.length)
    },
    removeOption(index) {
      if (this.isSingleMode) {
        this.resetSelectedOptions()
      } else {
        const res = findIndex(this.selected_option_indexes, function (option_index) {
          return option_index == index
        })
        if (res > -1) {
          this.selected_option_indexes.splice(res, 1)
        }
      }
    },

    resetSelectedOptions() {
      this.selected_option_indexes = []
    },
  },
}
</script>

<style scoped>
.scrollable-menu {
  height: auto;
  max-height: 200px;
  overflow-x: hidden;
}
</style>
