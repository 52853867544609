<template>
  <ol
    v-if="!changed"
    class="breadcrumb mb-0"
  >
    <template v-for="(route, index) in routes">
      <li
        v-if="routeName(route)"
        :key="route.path"
        class="breadcrumb-item"
        :class="{ active: false }"
      >
        <router-link
          v-if="index < routes.length - 1"
          :class="{ 'breadcrumb-item': true }"
          :to="route"
          @click.native="$root.$emit('l2nav', route)"
        >
          <span
            v-b-tooltip
            :data-testid="`header-breadcrumb-${index}-item`"
            :style="route.meta.title === null ? 'font-weight:700' : 'font-weight:400'"
            :title="routeName(route)"
          >
            {{ routeName(route) | truncate(50) }}
          </span>
        </router-link>
        <span
          v-else
          v-b-tooltip
          :data-testid="`header-breadcrumb-${index}-item`"
          :style="route.meta.title === null ? 'font-weight:700' : 'font-weight:400'"
          :title="routeName(route)"
        >
          {{ routeName(route) | truncate(50) }}
        </span>
      </li>
    </template>
  </ol>
</template>

<script>
import { mapGetters } from 'vuex'
import { t } from '@/I18n'

export default {
  name: 'page-header-title',

  data: function () {
    return {
      changed: false,
    }
  },
  computed: {
    ...mapGetters({
      main_titles: 'main_titles',
    }),
    routes: function () {
      const ret = []
      for (let i = 0; i < this.$route.matched.length; i++) {
        const route = this.$route.matched[i]
        if (i > 0) {
          let hide = false
          const prev_route = this.$route.matched[i - 1]

          if (route.meta.hide_in_breadcrumb) {
            hide = true
          }
          if (prev_route.meta.multi && i === this.$route.matched.length - 1) {
            hide = true
          }
          if (!hide && (route.meta.dummy !== true || route.meta.multi === true)) {
            ret.push(route)
          }
        } else if (route.meta.dummy !== true || route.meta.multi === true) {
          ret.push(route)
        }
      }
      return ret
    },
  },
  watch: {
    main_titles: {
      handler: function () {
        this.forceReload()
      },
      deep: true,
    },
  },
  mounted() {
    const _this = this
    this.$root.$on('update_main_title', ($event) => {
      _this.$log.debug('TITLE update_main_title', $event)
      _this.forceReload()
    })
  },

  methods: {
    plusHandler: function () {
      this.$log.debug('Emit plus')
      this.$emit('plus')
    },
    getRouteParent() {
      const all_routes = this.$route.matched.filter((e) => !e.meta.dummy)
      return all_routes && all_routes.length > 1
    },
    routeName(route) {
      if (route.name === 'dashboard_share') {
        return this.$store.getters.lang === 'fr' ? 'Tableau de bord partagé' : 'Shared dashboard'
      }

      if (route.meta.i18nAltTitle) {
        return t(route.meta.i18nAltTitle)
      }

      if (route.meta.i18nTitle) {
        return t(route.meta.i18nTitle)
      }

      if (route.meta.alt_title) {
        return this._(route.meta.alt_title).replace('░', '')
      }

      if (route.meta.title) {
        return this._(route.meta.title).replace('░', '')
      }

      if (route.name in this.main_titles) {
        return this.main_titles[route.name]
      }

      this.$log.debug('Cannot find title')
      return ''
    },
    forceReload() {
      this.$log.debug('TITLE force_reload')
      this.changed = true
      this.$nextTick(() => {
        this.changed = false
      })
    },
  },
  $route: {
    handler: function () {
      this.forceReload()
    },
  },
}
</script>

<style scoped>
.breadcrumb-item .active {
  cursor: default;
}
</style>
