<template>
  <div
    ref="panelElement"
    class="nds-panel-wrapper"
    tabindex="-1"
    @keydown.esc="$emit('close')"
  >
    <!-- Panel overlay -->
    <transition
      appear
      name="fade"
    >
      <div
        v-if="show"
        class="nds-panel-overlay"
        @click="$emit('close')"
      />
    </transition>

    <!-- Panel content -->
    <transition
      appear
      name="slide"
    >
      <div
        v-if="show"
        class="nds-panel"
        :class="{
          [size]: true,
        }"
        data-testid="nds-panel"
      >
        <!-- Panel content header -->
        <div
          v-if="$slots.header"
          class="nds-panel-header"
          data-testid="nds-panel-header"
        >
          <slot name="header" />

          <NdsButton
            class="nds-panel-close"
            :color="NdsButtonColor.TERTIARY"
            data-testid="nds-panel-close"
            :icon-left="NdsIconType.CLOSE"
            @click="$emit('close')"
          />
        </div>

        <!-- Panel content body -->
        <div
          class="nds-panel-body"
          data-testid="nds-panel-body"
        >
          <slot name="body" />
        </div>

        <!-- Panel content footer -->
        <div
          v-if="$slots.footer"
          class="nds-panel-footer"
          data-testid="nds-panel-footer"
        >
          <slot name="footer" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { type PropType, ref, watch } from 'vue'

// Interfaces
import { NdsPanelSize } from '@/components/NewDesignSystem/NdsPanel/NdsPanel.interfaces'
import { NdsButtonColor } from '@/components/NewDesignSystem/NdsButton/NdsButton.interfaces'
import { NdsIconType } from '@/components/NewDesignSystem/NdsIcon/NdsIcon.interfaces'

// Components
import NdsButton from '@/components/NewDesignSystem/NdsButton/NdsButton.vue'

const props = defineProps({
  size: {
    type: String as PropType<NdsPanelSize>,
    default: NdsPanelSize.MEDIUM,
  },
  show: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

defineEmits(['close'])

const panelElement = ref<HTMLElement | null>(null)

watch(
  () => props.show,
  () => {
    if (props.show && panelElement.value !== null) {
      panelElement.value.focus()
    }
  }
)
</script>

<style lang="postcss" scoped>
.nds-panel-wrapper {
  position: fixed;
  z-index: 2000;
}

.nds-panel-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.nds-panel {
  position: fixed;
  width: 800px;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  height: 100vh;
  @apply tw-bg-neutral-50;
  border-left: 1px solid;
  @apply tw-border-neutral-300;

  &.small {
    width: 430px;
  }

  &.medium {
    width: 800px;
  }

  &.large {
    width: 850px;
  }

  .nds-panel-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    @apply tw-gap-2;
    @apply tw-px-2;
    @apply tw-py-1;
    border-bottom: 1px solid;
    @apply tw-border-neutral-300;

    .nds-panel-close {
      margin-left: auto;
    }
  }

  .nds-panel-body {
    @apply tw-p-2;
    flex-grow: 1;
    overflow-y: auto;
  }

  .nds-panel-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;
    @apply tw-gap-2;
    @apply tw-p-2;
    border-top: 1px solid;
    @apply tw-border-neutral-300;
    @apply tw-bg-white;
  }
}

/* -----Fade transition----- */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* -----Slide transition------ */
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.4s ease-in-out;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}
</style>
